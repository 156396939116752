import CryptoJS from "crypto-js";

export const encrypt = (encryptMsg: string, privateKey = "docktimer") => {
  return CryptoJS.AES.encrypt(encryptMsg, privateKey).toString();
};

export const encryptDES = (encryptMsg: string, privateKey = "docktimer") => {
  return CryptoJS.DES.encrypt(encryptMsg, privateKey).toString();
};

export const decryptDES = (encryptMsg: string, privateKey = "docktimer") => {
  return CryptoJS.DES.decrypt(encryptMsg, privateKey);
};
