import { Box, Button, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LOCAL_STATE } from "../../../persist-store/app-store";
import { errorNotify } from "../../components/notify-components/notify/notify.ui";
import PasswordField from "../../components/text-fields/password-field.component";
import { FORM_VALIDATIONS } from "../../constants/formValidations";
import { changePassword } from "../../rest/authentication.rest";
import { updatePassword } from "../../rest/user-profile.rest";
import {
  getSessionStorage,
  removeSessionStorage,
} from "../../utils/session-storage/session-storage.utils";

const PasswordReset = ({
  isCurrentPasswordField,
  confirmButtonText = "Change Password",
  isVerificationPage,
}: {
  isCurrentPasswordField: boolean;
  confirmButtonText: string;
  isVerificationPage: boolean;
}) => {
  const navigation = useNavigate();
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);
  const [isNoticeModalOpen, setIsNoticeModalOpen] = useState(false);
  const [seconds, setSeconds] = useState(5);

  const { control, handleSubmit, getValues } = useForm();

  const submitForm = async (data: any) => {
    setIsPasswordUpdating(true);
    let payload = {};
    if (isVerificationPage) {
      try {
        await changePassword(
          data.new_password,
          getSessionStorage(LOCAL_STATE.RESET_TOKEN)
        );
        setIsNoticeModalOpen(true);
      } catch (e: any) {
        errorNotify(e?.response?.data?.message || "Some error occurred.");
      } finally {
        removeSessionStorage(LOCAL_STATE.RESET_TOKEN);
        setIsPasswordUpdating(false);
      }
    } else {
      try {
        await updatePassword({
          old_password: data?.current_password,
          new_password: data?.new_password,
        });
        setIsPasswordUpdating(false);
        setIsNoticeModalOpen(true);
      } catch (e: any) {
        setIsPasswordUpdating(false);
        errorNotify(e?.response?.data?.message || "Some error occurred.");
      }
    }
  };

  useEffect(() => {
    let timer: any = null;
    if (isNoticeModalOpen && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      navigation("/logout");
    }
    return () => {
      clearInterval(timer);
    };
  }, [isNoticeModalOpen, navigation, seconds]);

  return (
    <div>
      <div style={{ marginTop: 20 }} className="box-container gshadow-2">
        <Typography variant="h6">Update Password</Typography>
        <form onSubmit={handleSubmit(submitForm)}>
          <Box mt={2}>
            {isCurrentPasswordField ? (
              <Controller
                name="current_password"
                control={control}
                rules={FORM_VALIDATIONS.REQUIRED}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <PasswordField
                    label="Current Password"
                    value={value}
                    onChange={onChange}
                    error={error}
                  />
                )}
              />
            ) : null}
            <Controller
              name="new_password"
              control={control}
              rules={FORM_VALIDATIONS.REQUIRED}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PasswordField
                  label="New Password"
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              )}
            />

            <Controller
              name="confirm_new_password"
              control={control}
              rules={{
                ...FORM_VALIDATIONS.REQUIRED,
                validate: {
                  validatePwd: (value) =>
                    value === getValues().new_password ||
                    "Its must be same as New Password",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <PasswordField
                  label="Confirm New Password"
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              )}
            />
            <Box display="flex" justifyContent="end">
              <Button
                disabled={isPasswordUpdating}
                type="submit"
                variant="contained"
                color="success"
                style={{ marginTop: 10 }}
              >
                {confirmButtonText}
              </Button>
            </Box>
          </Box>
        </form>
      </div>
      <Modal
        keepMounted
        open={isNoticeModalOpen}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="modal-logout">
          <div>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Password has been changed successfully
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              You will be redirected to login screen to re login in {seconds}s
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PasswordReset;
