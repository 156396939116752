import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { styleConstants } from "../../../styles/StyleConstants";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const drawerWidth = 260;

export const useAppStyles = makeStyles({
  sideBar: {
    backgroundColor: styleConstants.WHITE_COLOR,
    height: "100%",
    marginTop: "2rem",
  },
  companyLogo: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    marginLeft: "0.5rem",
    padding: "4px"
  },
  drawerHeader: {
    backgroundColor: styleConstants.WHITE_COLOR,
    cursor: "pointer",
    height: "80px",
  },
  sideBarLogoImg: {
    width: "100%",
    height: 64,
    padding: "10px 10px",
  },
  sidebarlogoText: {
    color: styleConstants.PRIMARY_COLOR,
    fontWeight: "600",
    margin: "5px 0 0 0",
    fontSize: "20px",
    marginLeft: "10px;",
  },
  sideBarLinks: {
    color: `${styleConstants.DARK_GREY_COLOR} !important`,
    textDecoration: "none !important",
    backgroundColor: "#7777 !important",
    fontWeight: "600 !important",

  },
  activeLink: {
    backgroundColor: `${styleConstants.PRIMARY_COLOR} !important`,
    color: `${styleConstants.BACK_BLEND_MODE} !important`,
    fontWeight: "600 !important",
    borderRadius: "0.5rem !important",
    margin: "0 0.3rem 0 0.3rem !important",
  },
  appNavbar: {
    width: "100%",
  },
  textColor: {
    color: styleConstants.LIGHT_GREY_COLOR,
    fontWeight: "600",
  },
  appBar: {
    // backgroundColor: `${styleConstants.TERTIARY_COLOR} !important`,
    backgroundColor: `${styleConstants.WHITE_COLOR} !important`,
    backdropFilter: "blur(5px)",
    padding: "0.5rem 0 0 0",
    boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px !important",
  },
  flex: {
    display: "flex",
  },
  navProfile: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // background: "#fff",

    backgroundBlendMode: styleConstants.BACK_BLEND_MODE,
    // border: styleConstants.BORDER,
    borderRadius: styleConstants.BORDER_RADIUS_10,
    padding: "2px",
  },
  dropIcon: {
    color: styleConstants.PRIMARY_COLOR,
  },
  dropTranslate: {
    transform: "rotate(180deg)",
  },
  mainHeadings: {
    color: styleConstants.LIGHT_GREY_COLOR,
  }
});

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
