import BannerImage from "../../../../assets/img/banner-images/background_4.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./expense-management-page-styles";

import circleimg1 from "../../../../assets/img/servicebanner/service-inside-img/10-EM-AidExpenses.svg";
import circleimg2 from "../../../../assets/img/servicebanner/service-inside-img/11-EM-Godigital.svg";
import circleimg3 from "../../../../assets/img/servicebanner/service-inside-img/12-EM-MonitorSpending.png";

export const ExpenseManagement = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>EXPENSE MANAGEMENT</h2>
                <p>
                  Docktimers expense management feature is responsible for not
                  just tracking employee expenditure but also determining how
                  the business will refund the costs incurred. It also employs
                  the practices and rules that are used to manage such spending.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <div className="main">
          <div className="time-section">
            <div className="container">
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg1} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Aid extra expenses</h2>
                    </div>
                  </div>
                  <div className="col-lg-6 right-content">
                    <div className="section-content">
                      <p>
                        Pay solely for the actual work done and easily eliminate
                        your unnecessary expenses, reinvesting every saved cent
                        into something important and productive will serve as a
                        stepping stone to your business's success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg2} alt="" />
                  </div>
                </div>
                <div className="row responsive">
                  <div className="col-lg-6">
                    <div className="section-content-left">
                      <p>
                        Switch to modern times by using less paper and more data
                        in the digital form Scan and preserve your receipts
                        online to save money on paperwork and submitting
                        reports. Papers that are digitally stored can help you
                        prevent the danger of losing important documents and
                        confidential data.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 maim-content-head-right">
                    <div className="content-head">
                      <h2>Go Digital</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg3} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Supervise spending</h2>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-content">
                      <p>
                        Saving each penny is as important as making one. It’s
                        crucial to supervise our expenses from time to time. So
                        Docktimers' methodical and organized examination of
                        employee cost reports can assist your business in
                        eliminating unnecessary expenses and protecting the
                        company's bank balance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </StaticSkeleton>
  );
};
