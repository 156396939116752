
import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiCustomTextField } from '../../../components/mui-customs/custom-textField';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container xs={12} spacing={2} >
      <Grid item xs={12}>
        <Controller 
            name="line_one"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <MuiCustomTextField
                label="Address Line 1"
                placeholder=""
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller 
            name="line_two"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <MuiCustomTextField
                label="Address Line 2"
                placeholder=""
                required={false}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
              />
            )}
          />
        </Grid>
        
        <Grid item xs={6}>
          <Controller 
            name="city"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <MuiCustomTextField
                label="City"
                placeholder=""
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
              />
            )}
          />
        </Grid>
        
        <Grid item xs={6}>
          <Controller 
            name="state"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <MuiCustomTextField
                label="State"
                placeholder=""
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller 
            name="country"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <MuiCustomTextField
                label="Country"
                placeholder=""
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
              />
            )}
          />
        </Grid>
        
        <Grid item xs={6}>
          <Controller 
            name="zipcode"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error },
            }) => (
              <MuiCustomTextField
                label="Zipcode"
                placeholder=""
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
              />
            )}
          />
        </Grid>
    </Grid>
  )
}