
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button/Button';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiCustomTextField } from '../../../components/mui-customs/custom-textField';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
  
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
      <Controller 
          name="logo"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <div>
              <Button variant="contained" component="label">
                Upload Logo
                <input hidden accept="image/*" type="file" onChange={(e: any) => {
                  onChange(e.target.files[0])
                  if(props.manage){
                    props.updateOrgLogo(e.target.files[0]);
                  }
                }}/>
              </Button>
              {!value?.name.startsWith('api') && <Typography mt={1}>{value?.name || ""}</Typography>}
              {value?.name.startsWith('api') && <div className='mt-2'> <img src={`${process.env.REACT_APP_API_URL}/${value?.name}`} width={100}/> </div>}
              {Boolean(error) && <Typography mt={1} color={"red"} fontSize={13}>{error?.message?.toString()}</Typography>}
            </div>
          )}
        />
        
      </Grid>

      <Grid item xs={12}>
        <Controller 
          name="name"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <MuiCustomTextField
              label="Organization Name"
              placeholder=""
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error?.message?.toString()}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller 
          name="organization_email"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <MuiCustomTextField
              label="Organization Email"
              placeholder=""
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error?.message?.toString()}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller 
          name="about_us"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <MuiCustomTextField
              label="About Organization"
              multiline
              rows={3}
              placeholder=""
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error?.message?.toString()}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller 
          name="specialities"
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <MuiCustomTextField
              label="Specialities About Organization"
              multiline
              rows={3}
              placeholder=""
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              helperText={error?.message?.toString()}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}