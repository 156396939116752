import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";

interface WrapperProps {
  image: string;
}

export const Wrapper = styled.div<WrapperProps>`
  #hero {
    width: 100%;
    height: 800px;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat, no-repeat, repeat;
    overflow: hidden;

    .banner-section-title {
      margin-top: 10rem;

      p {
        letter-spacing: 0;
        color: ${styleConstants.WHITE_COLOR};
        line-height: 1.7;
        font-weight: 600;
        font-size: 1.5rem;
        margin: 6rem 17rem 0;
      }
    }
  }

  .box-area {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .box-area li {
    position: absolute;
    display: block;
    list-style: none;
    font-size: 100px;
    color: ${styleConstants.WHITE_COLOR};
    animation: animate 20s linear infinite;
    bottom: -170px;
  }

  .box-area li:nth-child(1) {
    left: 70%;

    animation-delay: 0s;
    border-radius: 50%;
  }

  .box-area li:nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation-delay: 1.5s;
    animation-duration: 10s;
  }

  .box-area li:nth-child(3) {
    left: 70%;

    animation-delay: 5.5s;
  }

  .box-area li:nth-child(4) {
    left: 42%;

    animation-delay: 0s;
    animation-duration: 15s;
  }

  .box-area li:nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
  }

  .box-area li:nth-child(6) {
    left: 14%;

    animation-delay: 3.5s;
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }

    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }

  .left-curve-img {
    background: ${styleConstants.SECONDARY_COLOR};
    height: 120px;
    width: 120px;
    border-radius: 0px 0px 0px 130px;
    left: 40.7%;
    top: 0;
    z-index: 1;
    position: absolute;
  }

  .right-curve-img {
    background: ${styleConstants.SECONDARY_COLOR};
    height: 120px;
    width: 120px;
    border-radius: 0px 0px 130px 0px;
    left: 50%;
    top: 0;
    z-index: 1;
    position: absolute;
  }

  .right-content {
    z-index: -1;
  }

  @media (min-width: 320px) and (max-width: 1024px) {
    #hero {
      .banner-section-title {
        p {
          margin: 0;
        }
      }
    }
    .time-section {
      .card-box {
        margin-top: 0;
        .img-center {
          display: flex;
          justify-content: center;
          .img-circle-top {
            height: 150px;
            width: 150px;
            position: relative;
            top: 0;
            left: 0;
          }
        }
        .section-content {
          margin-bottom: 2rem;
          p {
            margin-left: 0;
          }
        }
        .section-content-left {
          margin: 2rem 0rem;
          p {
            width: 100%;
            margin-left: 0rem;
          }
        }
        .maim-content-head-right {
          margin: 2rem 1rem 0;
          padding: 10px 0 0;
          border-radius: 40px 40px 40px 40px;
        }
        .responsive {
          flex-wrap: wrap-reverse;
        }
      }
    }
  }
`;
