import { getUniqueString } from "./get-unique-string.util";

export const signUpDefaultValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  middleName: "",
  organization_name: "",
  mobile: "",
  confirm_password: "",
  termsAndConditions: false,
};

export interface SignUpForm {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  mobile?: number;
  middleName?: string;
  organization_name: string;
  confirm_password: string;
  termsAndConditions: boolean;
}
export const loginDefaultValues = {
  email: "",
  password: "",
};

export const employeeTimesheetDefaultValues = {
  projectsList: [],
  startDate: "",
  endDate: "",
  uploadFile: "",
  projectTaskTime: "",
  selectedTask: [
    {
      key: getUniqueString(),
      name: "",
      hours: 0,
      minutes: 0,
    },
  ],
  taskHour: 0,
  taskMinute: 0,
};
export const projectDefaultValues = {
  projectName: "",
  projectSubTitle: "",
  projectDescription: "",
  projectTasks: [
    {
      key: getUniqueString(),
      taskName: "",
      taskDescription: "",
    },
  ],
};

export const projectSelectTask = {
  projectTasks: [
    {
      taskName: "",
      taskHours: "",
      taskMinute: "",
    },
  ],
};

export const forgotDefaultValues = {
  email: "",
};

export const verificationCodeDefaultValues = {
  verificationCode: "",
};

export const manageUserDefaultValues = {
  email: "",
  username: "",
};

export const employeeProfile = {
  name: "",
  email: "",
  mobileNo: "",
  gender: "",
  dob: "",
  address: "",
};

export const settingChangePwd = {
  oldPwd: "",
  newPwd: "",
  confirmPwd: "",
};

export const contactUsForm = {
  organizationName: "",
  companyEmail: "",
  Subject: "",
  Message: "",
};
