import { LOCAL_STATE } from "../../persist-store/app-store";
import { ROLES } from "../constants/app.constants";
import { SLUG_PLACEHOLDER } from "../constants/routes.constants";
import { User } from "../rest/rest-interfaces/user.interface";
import { getProfile } from "../rest/user-profile.rest";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from "./local-storage/local-storage.util";

export const getUserRole = (userObj: any) => {
  switch (userObj?.role || "") {
    case ROLES.SUBSCRIBER_ADMIN:
      return "Subscriber Admin";

    case ROLES.SUPER_ADMIN:
      return "Super Admin";

    case ROLES.HR:
      return "Human resource";

    case ROLES.EMPLOYEE:
      return "Employee";

    default:
      return "";
  }
};

export const getDaysArray = function (start: any, end: any) {
  const arr = [] as Date[];
  let dt = "" as unknown as Date;

  const startDate = new Date(start);
  const endDate = new Date(end);
  console.log("endDate:", endDate);

  for (dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const fetchSetCurrentUser = async () => {
  try {
    const response = await getProfile();
    if (response) {
      const userData: User | undefined = response;

      const data = userData?.user_id || userData;
      console.log(response);
      let retData: any = {
        ...data,
        profile_image: userData?.profile_image,
        orgName: userData?.organization_id?.name,
        orgLogo: userData?.organization_id?.logo || "",
        subdomain: userData?.organization_id?.organization_subdomain ?? "",
      };

      if (!data.organization && data?.role === ROLES.SUBSCRIBER_ADMIN) {
        return { navigate: true, ...retData };
      }

      retData.orgName = userData?.organization_id?.name || "";
      retData.orgLogo = userData?.organization_id?.logo || "";
      if (retData?.subdomain) {
        setToLocalStorage(LOCAL_STATE.SUB_DOMAIN, retData?.subdomain);
      }
      return retData;
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const handleLocalSubDomain = (type: string, value = "") => {
  if (type === "set") {
    setToLocalStorage(LOCAL_STATE.SUB_DOMAIN, value);
  } else if (type === "rm") {
    removeFromLocalStorage(LOCAL_STATE.SUB_DOMAIN);
  }
  return getFromLocalStorage(LOCAL_STATE.SUB_DOMAIN);
};

/**
 * This TypeScript function extracts the subdomain from a given URL.
 * @param {string} location - The `location` parameter is a string representing a URL.
 * @returns This function returns the subdomain from a given URL. If the URL is "www.example.com", the
 * function will return "example". If the URL is "subdomain.example.com", the function will return
 * "subdomain". If the URL is "localhost", the function will return an empty string.
 */
export const getSubDomainFromUrl = (location: string) => {
  const locationParts = location.split(".");

  let sliceTill = -2;
  const isLocalhost = locationParts.splice(-1)[0] === "localhost";

  if (isLocalhost) sliceTill = -1;

  return locationParts.slice(0, sliceTill).join("");
};

export const replaceSubDomain = (subDomain: string, path: string): string => {
  path = path.replace(SLUG_PLACEHOLDER.SUB_DOMAIN, subDomain); //TODO encrypt decrypt email

  return path;
};
