import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { LOCAL_STATE, useAppStore } from "../../../persist-store/app-store";
import { APP_ROUTE, getStaticRoutes } from "../../constants/routes.constants";

import { ImportexportPage } from "../../pages/importexport-page/importexport-page";

import { AboutUsPage } from "../../pages/static/about-us-page/about-us-page";
import { ContactUsPage } from "../../pages/static/contact-us-page/contact-us-page";
import { ExpenseManagement } from "../../pages/static/expense-management-page/expense-management-page";
import { HomePage } from "../../pages/static/home-page/home-page";
import { HRManagement } from "../../pages/static/hr-management-page/hr-management-page";
import { LeaveManagement } from "../../pages/static/leave-management-page/leave-management-page";
import { LoginPage } from "../../pages/static/login-page/login-page";
import { ReportManagement } from "../../pages/static/report-management-page/report-management-page";
import { SignUpPage } from "../../pages/static/signup-page/signup-page";
import { TimeManagement } from "../../pages/static/time-management-page/time-management-page";

import { ROLES } from "../../constants/app.constants";
import ApiDocs from "../../pages/api-docs/api-docs.page";
import LogoutPage from "../../pages/logout-page";
import { ForgotPasswordPage } from "../../pages/static/forgot-password-page/forgot-password-page";
import { PricingPage } from "../../pages/static/pricing-page/pricing-page";
import { VerificationCodePage } from "../../pages/static/verification-code-page/verification-code-page";
import VerifyEmailFromToken from "../../pages/static/verify-email/verify-email.page";
import { getSubDomain } from "../../rest/user-profile.rest";
import { setToLocalStorage } from "../../utils/local-storage/local-storage.util";
import { Loader } from "../elements/loader/loader-component";

const DashBoardPage = lazy(
  () => import("../../pages/dashboard-page/dashboard-page")
);
const Hrpage = lazy(() => import("../../pages/hr-page/hr-page"));
const ManageUsers = lazy(
  () => import("../../pages/manage-users-page/manage-users-page")
);
const MyProfilePage = lazy(
  () => import("../../pages/my-profile-page/my-profile-page")
);
const NotificationPage = lazy(
  () => import("../../pages/notification-page/notification-page")
);
const ProjectPage = lazy(() => import("../../pages/project-page/project-page"));
const Reportpage = lazy(() => import("../../pages/reports-page/reports-page"));
const SettingsPage = lazy(
  () => import("../../pages/settings-page/settings-page")
);
const ClientsPage = lazy(() => import("../../pages/clients/ClientsPage"));
const EmployeeAddTimeSheetPage = lazy(
  () =>
    import(
      "../../pages/employee-add-timesheet-page/employee-add-timesheet-page"
    )
);
const EmployeeTimeSheetPage = lazy(
  () => import("../../pages/employee-timsheet-page/employee-timsheet-page")
);
const OnBoardEmployeePage = lazy(
  () => import("../../pages/on-board-employee/on-board-employee.component")
);
const OrganizationPage = lazy(
  () => import("../../pages/organization/organisation")
);

const AppRouter = () => {
  const [user, setUser] = useState<any>({});
  console.log("user:", user);
  const [subDomain, setSubDomain] = useState("");

  const {
    accessToken,
    setCurrentUser,
    isAuthenticated,
    setIsAuthenticated,
    currentUser,
  } = useAppStore();
  console.log("currentUser:", currentUser);
  const navigate = useNavigate();

  // const fetchCurrentUser = useCallback(async () => {
  //   try {
  //     const resp = await fetchSetCurrentUser();
  //     console.log("resp:", resp);
  //     if (resp.navigate) {
  //       setCurrentUser(resp as User);
  //       setUser(resp);
  //       navigate(APP_ROUTE.ORG_CREATE);
  //     } else {
  //       setCurrentUser(resp as User);
  //       setUser(resp);
  //     }
  //   } catch {
  //     console.log("eeee");
  //     setUser({});
  //   }
  // }, [navigate, setCurrentUser]);

  const fetchSubDomain = async () => {
    try {
      const res = await getSubDomain();
      console.log("res:", res.sub_domain);
      if (res) {
        return res.sub_domain;
      }
    } catch {
      console.error("error while getting subdomain");
    }
  };

  useEffect(() => {
    if (!!accessToken) {
      // fetchCurrentUser();
      setIsAuthenticated(true);
    }
  }, [accessToken, setIsAuthenticated]);

  const assignSubDomainToUrl = useCallback(async () => {
    const mustNotInclude = [...getStaticRoutes(), APP_ROUTE.ORG_CREATE, "/"];

    if (!mustNotInclude.includes(window.location.pathname)) {
      const subDomain = await fetchSubDomain();
      console.log("1:", subDomain);
      setSubDomain(subDomain);
      setToLocalStorage(LOCAL_STATE.SUB_DOMAIN, subDomain);

      if (!isAuthenticated) {
        navigate(APP_ROUTE.STATIC.HOMEPAGE);
      }
    }
  }, [isAuthenticated, navigate]);

  const AuthenticatedRoutes = useMemo(
    () => [
      {
        path: "/",
        Element: <DashBoardPage />,
        caseSensitive: true,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.DASHBOARD,
        Element: <DashBoardPage />,
        caseSensitive: true,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.TIME_SHEET,
        Element: <EmployeeTimeSheetPage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.ADDTIMESHEET,
        Element: <EmployeeAddTimeSheetPage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR],
      },
      {
        path: APP_ROUTE.PROJECT,
        Element: <ProjectPage />,
        caseSensitive: false,
        access: [ROLES.SUBSCRIBER_ADMIN, ROLES.HR],
      },
      {
        path: APP_ROUTE.REPORTS,
        Element: <Reportpage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.SETTINGS,
        Element: <SettingsPage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.IMPORTEXPORT,
        Element: <ImportexportPage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.NOTIFICATION,
        Element: <NotificationPage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.HR,
        Element: <Hrpage />,
        caseSensitive: false,
        access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.MY_PROFILE,
        Element: <MyProfilePage />,
        caseSensitive: false,
        access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.MANAGE_USERS,
        Element: <ManageUsers />,
        caseSensitive: true,
        access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.ON_BOARD_EMPLOYEE,
        Element: <OnBoardEmployeePage />,
        caseSensitive: true,
        access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.EDIT_EMPLOYEE,
        Element: <OnBoardEmployeePage />,
        caseSensitive: true,
        access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.API_DOCS,
        Element: <ApiDocs />,
        caseSensitive: true,
        access: [ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.CLIENTS,
        Element: <ClientsPage />,
        caseSensitive: true,
        access: [ROLES.SUBSCRIBER_ADMIN, ROLES.HR],
      },
      {
        path: APP_ROUTE.ORG,
        Element: <OrganizationPage manage={true} />,
        caseSensitive: true,
        access: [ROLES.SUBSCRIBER_ADMIN],
      },
      {
        path: APP_ROUTE.ORG_CREATE,
        Element: <OrganizationPage manage={false} />,
        caseSensitive: true,
        access: [ROLES.SUBSCRIBER_ADMIN],
      },
    ],
    []
  );

  if (isAuthenticated && currentUser) {
    return (
      <Routes>
        {AuthenticatedRoutes.map((r: any) =>
          r.access.includes(currentUser.role) ? (
            <Route
              caseSensitive={r.caseSensitive}
              path={r.path}
              element={<Suspense fallback={<Loader />}>{r.Element}</Suspense>}
            />
          ) : null
        )}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
        <Route path="/logout" element={<LogoutPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route caseSensitive path={"/"} element={<HomePage />} />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.ABOUT_PAGE}
        element={<AboutUsPage />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.CONTACT_PAGE}
        element={<ContactUsPage />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.TIME_MANAGE_PAGE}
        element={<TimeManagement />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.LEAVE_MANAGE_PAGE}
        element={<LeaveManagement />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.HR_MANAGE_PAGE}
        element={<HRManagement />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.EXPENSE_MANAGE_PAGE}
        element={<ExpenseManagement />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.REPORT_MANAGE_PAGE}
        element={<ReportManagement />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.PRICING}
        element={<PricingPage />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.LOGIN}
        element={<LoginPage />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.SIGN_UP}
        element={<SignUpPage />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.VERIFY_EMAIL}
        element={<VerifyEmailFromToken />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.FORGOT_PASSWORD}
        element={<ForgotPasswordPage />}
      />
      <Route
        caseSensitive
        path={APP_ROUTE.STATIC.VERIFY_CONFIRMATION_CODE}
        element={<VerificationCodePage />}
      />
      <Route path={APP_ROUTE.STATIC.HOMEPAGE} element={<HomePage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path={APP_ROUTE.DASHBOARD} element={<></>} />
      <Route
        path={APP_ROUTE.ORG_CREATE}
        element={<OrganizationPage manage={false} />}
      />
      {/* Redirect to Default Path */}
    </Routes>
  );
};

export default AppRouter;
