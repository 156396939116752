// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("montserrat-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("montserrat-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("montserrat-bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("montserrat-bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("inter-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("inter-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("inter-bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("inter-bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 17, 2023 */

@font-face {
  font-family: "montserratregular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "interregular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "interbold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/stylesheet.css"],"names":[],"mappings":"AAAA,mFAAmF;;AAEnF;EACE,gCAAgC;EAChC;0DAC+C;EAC/C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B;0DAC4C;EAC5C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B;0DAC0C;EAC1C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB;0DACuC;EACvC,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 17, 2023 */\n\n@font-face {\n  font-family: \"montserratregular\";\n  src: url(\"montserrat-regular.woff2\") format(\"woff2\"),\n    url(\"montserrat-regular.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"montserratbold\";\n  src: url(\"montserrat-bold.woff2\") format(\"woff2\"),\n    url(\"montserrat-bold.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"interregular\";\n  src: url(\"inter-regular.woff2\") format(\"woff2\"),\n    url(\"inter-regular.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"interbold\";\n  src: url(\"inter-bold.woff2\") format(\"woff2\"),\n    url(\"inter-bold.woff\") format(\"woff\");\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
