import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";

export const Wrapper = styled.div`
  // ------------------------------------------
  // # signup
  // --------------------------------------------------------------*/

  .login-image{
    height: 450px;
    img{
      height: 100%;
      width: 100%;
    }
  }

  .main-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .logo {
      img {
        height: 90px;
        margin-right: 10px;
      }
      span {
        margin-top: 7px;
        font-size: 22px;
        color: ${styleConstants.WHITE_COLOR};
        font-weight: 600;
        letter-spacing: 2px;
      }
    }
  }

  .main-logo-text {
    font-weight: 900;
    letter-spacing: 2px;
    color: ${styleConstants.PRIMARY_COLOR};
    text-shadow: 1px 1px #000;

    span {
      color: ${styleConstants.SECONDARY_COLOR};
    }
  }

  #signup {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup .info {
    border-top: 3px solid ${styleConstants.PRIMARY_COLOR};
    border-bottom: 3px solid ${styleConstants.PRIMARY_COLOR};
    padding: 30px;
    background: ${styleConstants.WHITE_COLOR};
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  }

  .signup .info i {
    font-size: 20px;
    color: ${styleConstants.PRIMARY_COLOR};
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .signup .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${styleConstants.PRIMARY_COLOR};
  }

  .signup .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
  }

  .signup .info .email p {
    padding-top: 5px;
  }

  .signup .info .social-links {
    padding-left: 60px;
  }

  .signup .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: ${styleConstants.WHITE_COLOR};
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }

  .signup .info .social-links a:hover {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

  .signup .info .email:hover i,
  .signup .info .address:hover i,
  .signup .info .phone:hover i {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

  .signup .signup-form {
    width: 100%;
    padding: 15px 8px;
  }

  .signup .signup-form .form-group {
    margin-bottom: 10px;
  }



  .signup .signup-form input,
  .signup .signup-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }

  .signup .signup-form input:focus,
  .signup .signup-form textarea:focus {
    border-color: ${styleConstants.PRIMARY_COLOR};
  }

  .signup .signup-form .form-control {
    height: 44px;
    border-radius: 25px;
  }

  .signup .signup-form textarea {
    padding: 10px 12px;
  }

  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .form-group,
  .form-check,
  .password {
    color: ${styleConstants.PRIMARY_COLOR};
    font-weight: 600;
    cursor: pointer;
  }
  .signup-container {
    // background: rgb(50, 50, 187);
    // background: linear-gradient(
    //   8deg,
    //   rgba(50, 50, 187, 1) 35%,
    //   rgba(167, 108, 50, 1) 100%
    // );
    background-color: #f3f5ff;
    padding: 10px;
  }

  .signuppage {
    // border-radius: 60px;
    // box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    // margin-bottom: 20px;
    // background-color: ${styleConstants.WHITE_COLOR};

    h3 {
      font-size: 1.5rem;
      color: ${styleConstants.PRIMARY_COLOR};
      font-weight: 900;
      letter-spacing: 2px;
    }
    h2 {
      font-size: 36px;
      text-align: center;
      color: ${styleConstants.PRIMARY_COLOR};
      font-weight: 900;
      margin-top: 20px;
      line-height: 1.21;
      letter-spacing: 2px;
    }

    .side-img {
      width: 100%;
    }

    .formpad {
      padding: 20px 50px 20px;
    }

    .back-color {
      width: 100%;
      border-top: 3px solid ${styleConstants.PRIMARY_COLOR};
      border-bottom: 3px solid ${styleConstants.PRIMARY_COLOR};
      padding: 15px;
      background: ${styleConstants.WHITE_COLOR};
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      .Logo-Name{
        display:flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        img{
          width:60px;
          margin-right: 10px;
        }
        h3{
          margin-top: 13px;
        }

      }
    }

    .google-button {
      position: relative;
      height: 62px;
      padding: 0 0 0 50px;
      width: 100%;
      box-shadow: none;
      -webkit-appearance: none;
      outline: none;
      border-radius: 16px;
      border: solid 2px #f2f1f2;
      background: white;
      background-color: ${styleConstants.WHITE_COLOR};
      color: #7b7b91;
      font-weight: 500;
      font-size: 18px;
    }

    .google-button .google-button-icon {
      height: 38px;
      position: absolute;
      left: 14px;
      top: 10px;
      bottom: 2px;
      border-radius: 2px;
      background: ${styleConstants.WHITE_COLOR};
    }

    .signuplink {
      p {
        font-weight: 600;
        color: ${styleConstants.PRIMARY_COLOR};
        margin:0;
      }

      span {
        color: ${styleConstants.SECONDARY_COLOR};
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .submit-btn {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 28px 11px 28px;
      border-radius: 50px;
      transition: 0.5s;
      color: ${styleConstants.WHITE_COLOR};
      background: ${styleConstants.PRIMARY_COLOR};
      border: 2px solid ${styleConstants.WHITE_COLOR};
    }

    .submit-btn:hover {
      background: ${styleConstants.WHITE_COLOR};
      color: ${styleConstants.SECONDARY_COLOR};
      border: 2px solid ${styleConstants.PRIMARY_COLOR};
    }
  }
  .requiredmessage {
    color: red;
    font-size: 14px;
    margin-left: 14px;
  }
  .required {
    color: red;
  }

  
  flex: {
    display:flex,
    flex-direction: column;
  }

@media (min-width: 320px) and (max-width: 767px){
     .main-logo {
        margin-top: 1rem;
    }
    .signuppage{
        .formpad {
            padding: 10px;
        }
        .back-color{
            margin-bottom: 20px;
        }
    }
}

`;
