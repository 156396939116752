import BannerImage from "../../../../assets/img/banner-images/background_7.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./report-management-page-styles";

import circleimg1 from "../../../../assets/img/servicebanner/service-inside-img/18-RM-HolisticAnalysis.svg";
import circleimg2 from "../../../../assets/img/servicebanner/service-inside-img/19-RM-EnchanceProductivity.svg";
import circleimg3 from "../../../../assets/img/servicebanner/service-inside-img/20-RM-TrackTrendsandForecast.svg";

export const ReportManagement = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>REPORT MANAGEMENT</h2>
                <p>
                  Our report management system will assist you in better
                  analyzing by providing clear and exact information about the
                  work state, which will greatly assist you in keeping track of
                  the changes and tasks that must be completed.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <div className="main">
          <div className="time-section">
            <div className="container">
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg1} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>HolisticAnalysis</h2>
                    </div>
                  </div>
                  <div className="col-lg-6 right-content">
                    <div className="section-content">
                      <p>
                        The report management system will assist you in
                        obtaining an overall holistic perspective of all the
                        activities merged together, which will assist you in
                        tracing better and completing the work in the allotted
                        time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg2} alt="" />
                  </div>
                </div>
                <div className="row responsive">
                  <div className="col-lg-6">
                    <div className="section-content-left">
                      <p>
                        Just tracking the time and following it won’t prove to
                        be fruitful if its outcome is not productive. Our
                        detailed and well-structured reports will help the
                        managers to make quick decisions which in turn will
                        enhance the team productivity without any interruptions
                        or delays.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 maim-content-head-right">
                    <div className="content-head">
                      <h2>Enchance Productivity</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg3} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Track Trends and Forecast</h2>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-content">
                      <p>
                        Our report management system will assist you in tracking
                        the trend of the workflow, providing you with forecast
                        reports on the work progress and the potential of
                        completing the assignment within the defined time frame.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </StaticSkeleton>
  );
};
