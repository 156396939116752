import axios from "axios";
import { User } from "./rest-interfaces/user.interface";

export const getUserProfile = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/employee/profile/getEmployeeProfile`
  );

  return response.data;
};

export const getProfile = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/profile/getProfile`
  );

  console.log("response:", response);

  return (response.data.data as User) || (response.data.result as User);
};

export const getSubDomain = async (accessToken?: string) => {
  if (accessToken) {
    axios.defaults.headers.common["authorization-token"] = `${accessToken}`;
  }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/auth/get-subdomain`
  );

  return response.data;
};
export const updateProfile = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/profile/update`,
    payload
  );

  return response.data;
};

export const updateProfileImage = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/profile/updateProfilePicture`,
    payload
  );

  return response.data;
};

export const updatePassword = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/updatePassword`,
    payload
  );

  return response.data;
};
