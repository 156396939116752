import { Link } from "react-router-dom";
import footerImg from "../../../../assets/img/docktimer/footer-bg.png";
import { APP_ROUTE } from "../../../constants/routes.constants";
import { Wrapper } from "./footer-style-component";

export function FooterComponent() {
  return (
    <>
      <Wrapper image={footerImg}>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>DockTimer</h3>
                  <p>
                    Hyderabad, India
                    <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <Link to="tel:(+91 9920765883)">+91 0000 0000 00</Link>
                    <br />
                    <strong>Email:</strong>{" "}
                    <Link to="mailto:info@docktimer.com">
                      {" "}
                      info@docktimer.com
                    </Link>
                    <br />
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Quick Links</h4>
                  <ul>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.HOMEPAGE}>Home</Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.ABOUT_PAGE}>About us</Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.HOMEPAGE}>Terms of service</Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.HOMEPAGE}>Privacy policy</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.TIME_MANAGE_PAGE}>
                        Time Management
                      </Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.LEAVE_MANAGE_PAGE}>
                        Leave Management
                      </Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.EXPENSE_MANAGE_PAGE}>
                        Expense Management
                      </Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.HR_MANAGE_PAGE}>Hr Management</Link>
                    </li>
                    <li>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <Link to={APP_ROUTE.STATIC.REPORT_MANAGE_PAGE}>
                        Report Management
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Social Networks</h4>
                  <div className="social-links mt-3">
                    <a
                      href="https://twitter.com/thedocktimer?t=g0LmHzRp2IsUaeUztCYA9g&s=08"
                      target="_blank"
                      className="btn-floating btn-small btn-tw waves-effect waves-light" rel="noreferrer"
                    >
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100088889851940"
                      className="btn-floating btn-small btn-fb waves-effect waves-light" rel="noreferrer"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/docktimer"
                      className="btn-floating btn-small btn-li waves-effect waves-light" rel="noreferrer"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/docktimer"
                      className="btn-floating btn-small btn-In waves-effect waves-light" rel="noreferrer"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom clearfix">
            <div className="container">
              <div className="copyright">
                &copy; Copyright{" "}
                <strong>
                  <span>DockTimer</span>
                </strong>
                . All Rights Reserved 2022
              </div>
              <div className="credits">
                Designed by DockTimer<Link to={`timeManagement`}></Link>
              </div>
            </div>
          </div>
        </footer>
      </Wrapper>
    </>
  );
}
