import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import DocktimerLogo from "../../../../assets/img/docktimer/DockTimer_withoutshadow.svg";
import {
  errorNotify, successNotify
} from "../../../components/notify-components/notify/notify.ui";
import { ErrorTextfield } from "../../../components/notify-components/warn-textfield/warn-textfield";
import {
  APP_ROUTE, SLUG_PLACEHOLDER
} from "../../../constants/routes.constants";
import { requestPasswordChange } from "../../../rest/authentication.rest";
import { forgotDefaultValues } from "../../../utils/form-utils";
import { forgotFromSchema } from "../../../utils/schema-validations/schema-validations-utils";

import { useTranslation } from "react-i18next";
import { LOCAL_STATE } from "../../../../persist-store/app-store";
import { removeSessionStorage } from "../../../utils/session-storage/session-storage.utils";
import { Wrapper } from "./forgot-password-style";

export const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate(APP_ROUTE.STATIC.LOGIN);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: forgotDefaultValues,
    resolver: yupResolver(forgotFromSchema),
  });

  const onSubmit = async function (data: { email: string }) {
    removeSessionStorage(LOCAL_STATE.RESET_TOKEN)
    try {
      const response = await requestPasswordChange(data.email);

      if (response) {
        successNotify(t("server.success-verification-code"));
        let path = APP_ROUTE.STATIC.VERIFY_CONFIRMATION_CODE;
        path = path.replace(SLUG_PLACEHOLDER.EMAIL_ID, data.email); //TODO encrypt decrypt email
        console.log('path:', path);
        // navigate(path);
        window.location.pathname = path
      }
    } catch (err) {
      return errorNotify(
        ((err as AxiosError).response?.data as AxiosError).message
      );
    }
  };

  const RequiredSpan = () => (
    <span style={{ color: "orange", marginLeft: "0.2rem" }}>*</span>
  );

  return (
    <Wrapper>
      <div className="login-container">
        <section id="login" className="login">
          <div className="loginpage" data-aos="fade-up">
            <div className="formpad">
              <div className="logo">
                <Link to={"/home"}>
                  <img alt="" src={DocktimerLogo} />
                </Link>
              </div>
              <div className="back-color">
                <div className="text-center">
                  <h3>{t("label.forgot-password")}</h3>
                </div>

                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="name">
                      {t("label.email")} <RequiredSpan />
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            type="text"
                            placeholder={t("label.enter-email") as string}
                            {...field}
                          />
                        );
                      }}
                    />
                    {errors.email?.message && (
                      <ErrorTextfield>
                        {t("message.field-is-required", {
                          field: t("label.email"),
                        })}
                      </ErrorTextfield>
                    )}
                  </div>
                  <div className="text-center">
                    <button className="submit-btn">
                      {" "}
                      {t("label.send-verification-code")}
                    </button>
                  </div>
                  <div className="signuplink text-center mt-3">
                    <p onClick={navigateToLogin}>
                      <ArrowBackIcon />
                      &nbsp; {t("label.back-to-login")}{" "}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End login Section */}
      </div>
    </Wrapper>
  );
};
