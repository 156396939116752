import { encrypt } from "../utils/hash-passcode.util";
import axios from "./axios";

export const appRegister = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/register`,
    payload
  );

  return response.data;
};

export const appLogin = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/login`,
    payload
  );

  return response.data;
};

/**
 * It sends a POST request to the `/api/subscriber/login` endpoint with the `email` and `password` from
 * the payload
 * @param payload - {
 * @returns The response.data is being returned.
 */
export const subscriberLogin = async (payload: {
  email: string;
  password: string;
}) => {
  const _payload = {
    data: encrypt(JSON.stringify(payload), "docktimer"),
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/subscriber/login`,
    _payload
  );

  return response.data;
};

export const addSubscriber = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/subscriber/createSubscriber`,
    payload
  );

  return response.data;
};

export const requestPasswordChange = async (email: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/requestPasswordChange`,
    {
      email,
    }
  );

  return response.data;
};

export const verifyCode = async (email: string, resetCode: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/verifyResetToken`,
    {
      email,
      resetCode,
    }
  );

  return response.data;
};

export const verifyEmail = async (employeeId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/auth/verifyEmail/${employeeId}`
  );

  return response.data;
};

export const sendVerificationEmail = async (email: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/sendVerification`,
    {
      email,
    }
  );

  return response.data;
};

export const changePassword = async (password: string, resetToken: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/changePassword`,
    {
      password,
      resetToken,
    }
  );

  return response.data;
};
