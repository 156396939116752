import axios from "axios";

/**
 * It makes a GET request to the `/api/docs` endpoint and returns the data
 * @returns An array of objects.
 */
export const getDocs = async () => {
  const docs = await axios.get(`${process.env.REACT_APP_API_URL}/api/docs`);

  return docs.data;
};
