import AddTaskIcon from "@mui/icons-material/AddTask";
import ApiRoundedIcon from "@mui/icons-material/ApiRounded";
import ArticleIcon from "@mui/icons-material/Article";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import Logout from "@mui/icons-material/Logout";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SettingsIcon from "@mui/icons-material/Settings";

import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import {
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AppLogo } from "../../../assets/img/docktimer/DockTimer_Logo.svg";
import { LOCAL_STATE, useAppStore } from "../../../persist-store/app-store";
import { styleConstants } from "../../../styles/StyleConstants";
import { HEADINGS, ROLES } from "../../constants/app.constants";
import { APP_ROUTE } from "../../constants/routes.constants";
import { User, UserId } from "../../rest/rest-interfaces/user.interface";
import {
  fetchSetCurrentUser,
  getUserRole,
  replaceSubDomain,
} from "../../utils/common.utils";
import { getFromLocalStorage } from "../../utils/local-storage/local-storage.util";
import getInitials from "../../utils/name-initials";
import { Wrapper } from "./app-wrapper-style";
import { AppWrapperProps } from "./app-wrapper.interface";
import {
  AppBar,
  Drawer,
  DrawerHeader,
  useAppStyles,
} from "./app-wrapper.styles";

const AppWrapper: FunctionComponent<AppWrapperProps> = ({
  children,
}: AppWrapperProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any>({});
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const [needOrg, setNeedOrg] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const classes = useAppStyles();
  const { accessToken, currentUser, setCurrentUser } = useAppStore();

  const subDomain = () => getFromLocalStorage(LOCAL_STATE.SUB_DOMAIN) as string;

  const sidebarData = [
    {
      title: "Dashboard",
      icons: <DashboardRoundedIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.DASHBOARD),
      index: 0,
      access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      title: "MyProfile",
      icons: <PersonRoundedIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.MY_PROFILE),
      index: 1,
      access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 2,
      title: "Employee Timesheets",
      icons: <MoreTimeIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.HR),
      access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 3,
      title: "Timesheets",
      icons: <MoreTimeIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.TIME_SHEET),
      access: [ROLES.EMPLOYEE, ROLES.HR],
    },
    {
      index: 4,
      title: "Projects",
      icons: <AddTaskIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.PROJECT),
      access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 5,
      title: "Reports",
      icons: <ArticleIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.REPORTS),
      access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 6,
      title: "Settings",
      icons: <SettingsIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.SETTINGS),
      access: [ROLES.EMPLOYEE, ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 7,
      title: "OnBoard Employees",
      icons: <PersonAddAltRoundedIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.MANAGE_USERS),
      access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 8,
      title: "Clients",
      icons: <PersonAddAltRoundedIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.CLIENTS),
      access: [ROLES.HR, ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 9,
      title: "Api Docs",
      icons: <ApiRoundedIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.API_DOCS),
      access: [ROLES.SUBSCRIBER_ADMIN],
    },
    {
      index: 10,
      title: "Organization",
      icons: <ApiRoundedIcon />,
      link: replaceSubDomain(subDomain(), APP_ROUTE.ORG),
      access: [ROLES.SUBSCRIBER_ADMIN],
    },
  ];

  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const find: any = HEADINGS.find((v: any) =>
      location.pathname.includes(v.link)
    );
    if (find) {
      setTitle(find.heading);
    } else {
      setTitle("");
    }
  }, [location]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    navigate(APP_ROUTE.LOGOUT);
  };

  const handleMenuClick = (link: String) => {
    if (link === "Logout") {
      handleLogout();
    } else if (link === "Profile") {
      navigate(APP_ROUTE.MY_PROFILE);
    }
    handleCloseUserMenu();
  };

  const fetchCurrentUser = useCallback(async () => {
    try {
      const resp = await fetchSetCurrentUser();
      console.log(resp, "RESP");
      if (resp.navigate) {
        if (
          !(resp as UserId)?.organization &&
          (resp as UserId)?.role === "SUBSCRIBER_ADMIN"
        ) {
          setCurrentUser(resp as User);

          setNeedOrg(true);
          setOpen(false);
          navigate(APP_ROUTE.ORG_CREATE);
        } else {
          setCurrentUser(resp as User);

          setNeedOrg(false);
          setOpen(true);
        }
        // setCurrentUser(resp as User);
        // setUser(resp);
        // navigate(APP_ROUTE.ORG_CREATE);
      } else {
        setCurrentUser(resp as User);
        setUser(resp);
      }
    } catch {
      setUser({});
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(accessToken)) {
      fetchCurrentUser();
      setIsAuthenticated(true);
    }
  }, [accessToken, fetchCurrentUser]);

  // useEffect(() => {
  //   console.log(currentUser)
  //   setUser(currentUser);
  //   if (
  //     !(currentUser as UserId)?.organization &&
  //     (currentUser as UserId)?.role === "SUBSCRIBER_ADMIN"
  //   ) {
  //     setNeedOrg(true);
  //     setOpen(false);
  //   } else {
  //     setNeedOrg(false);
  //     setOpen(true);
  //   }
  // }, [currentUser]);

  console.log("isAuthenticated", isAuthenticated);
  console.log("currentUser", currentUser);
  console.log("userRole", currentUser?.role);
  return isAuthenticated ? (
    <Wrapper>
      <Box className={classes.flex}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          className={classes.appBar}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              disabled={needOrg}
            >
              <MenuOpenIcon color={open ? "primary" : "secondary"} />
            </IconButton>
            <Box display="flex" width={"100%"} ml={1}>
              <Typography
                variant="h5"
                fontWeight="bold"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                className={classes.mainHeadings}
              >
                {title}
              </Typography>
            </Box>
            <Grid
              container
              display="flex"
              justifyContent="flex-end"
              flexWrap="nowrap"
            >
              {(currentUser as User)?.orgName && (
                <Grid>
                  <Box
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      display: "flex",
                    }}
                    mr={2}
                    className="company-container"
                  >
                    <Typography
                      className="company-name"
                      lineHeight={1}
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {(currentUser as User)?.orgName || ""}
                    </Typography>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      // className={classes.flex}
                      style={{
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className={classes.companyLogo}
                        src={
                          ((currentUser as User)?.orgLogo as string)
                            ? `${process.env.REACT_APP_API_URL}/${
                                (currentUser as User)?.orgLogo
                              }`
                            : ""
                        }
                        alt="company-logo"
                      />
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                className={classes.flex}
                style={{
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Box
                  className={classes.flex}
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ paddingRight: "2rem" }} ml={2}>
                    <Badge color="primary" style={{ cursor: "pointer" }}>
                      <Badge color="secondary" variant="dot">
                        <NotificationsActiveRoundedIcon color="primary" />
                      </Badge>
                    </Badge>
                  </Box>
                  <div className={classes.navProfile}>
                    <IconButton
                      sx={{ p: 0 }}
                      className="profile-hover"
                      size="small"
                      onClick={handleOpenUserMenu}
                    >
                      {(currentUser as User)?.profile_image ? (
                        <Avatar src={(currentUser as User)?.profile_image} />
                      ) : (
                        <Avatar sizes="">
                          {getInitials(
                            `${(currentUser as UserId)?.firstname || ""} ${
                              (currentUser as UserId)?.lastname || ""
                            }`
                          )}
                        </Avatar>
                      )}
                    </IconButton>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem>
                        <Box>
                          <Typography
                            color="#000"
                            fontSize={16}
                            fontWeight="bold"
                          >
                            {(currentUser as UserId)?.firstname}{" "}
                            {(currentUser as UserId)?.lastname}
                          </Typography>
                          <Typography
                            color="gray"
                            fontSize={14}
                            fontWeight="bold"
                          >
                            {getUserRole(currentUser)}
                          </Typography>
                        </Box>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        key={"Profile"}
                        onClick={() => handleMenuClick("Profile")}
                        disabled={needOrg}
                      >
                        <PermIdentityIcon fontSize="small" />
                        <Typography textAlign="center" color="#000" ml={2}>
                          {"Profile"}
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        key={"Logout"}
                        onClick={() => handleMenuClick("Logout")}
                      >
                        <Logout fontSize="small" />
                        <Typography textAlign="center" color="#000" ml={2}>
                          {"Logout"}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          className={classes.sideBar}
          hidden={needOrg}
        >
          <DrawerHeader className={classes.drawerHeader}>
            <Stack
              position="sticky"
              top="0"
              left="0"
              zIndex="999"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              minHeight={64}
            >
              <AppLogo width="50px" height="50px" />
              <span className={classes.sidebarlogoText}>DockTimer</span>
            </Stack>
          </DrawerHeader>
          <List className={classes.sideBar}>
            {sidebarData.map((sideData, index) =>
              sideData.access.includes(currentUser?.role) ? (
                <Tooltip
                  title={sideData.title}
                  placement="right-start"
                  arrow
                  key={sideData.index}
                >
                  <Link
                    onClick={() => {
                      navigate(replaceSubDomain(subDomain(), sideData.link));
                    }}
                    className={classes.sideBarLinks}
                  >
                    <Slide in>
                      <ListItem
                        key={sideData.title}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          className={
                            location.pathname.includes(sideData.link)
                              ? classes.activeLink
                              : ""
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              color: location.pathname.includes(sideData.link)
                                ? styleConstants.WHITE_COLOR
                                : styleConstants.LIGHT_GREY_TEXT_COLOR,
                            }}
                          >
                            {sideData.icons}
                          </ListItemIcon>
                          <ListItemText
                            primary={sideData.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              textDecoration: "none",
                              letterSpacing: 2,
                              fontWeight: "600",
                              color: location.pathname.includes(sideData.link)
                                ? styleConstants.WHITE_COLOR
                                : classes.textColor,
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Slide>
                  </Link>
                </Tooltip>
              ) : null
            )}
          </List>
        </Drawer>
        <Box
          component="div"
          sx={{ flexGrow: 1, pl: 3, pr: 3, pt: 2, background: "#EFF2FF" }}
          minHeight="100vh"
        >
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </Wrapper>
  ) : (
    <>{children}</>
  );
};

export default AppWrapper;
