import axios from "axios";
import { OnBoardUser } from "./rest-interfaces/subscriber.interface";

export const createHrOrEmployee = async (
  payload: OnBoardUser,
  isHR: Boolean
) => {
  const response = await axios.post<OnBoardUser>(
    !isHR
      ? `${process.env.REACT_APP_API_URL}/api/hr/onBoardEmployee`
      : `${process.env.REACT_APP_API_URL}/api/subscriber/onBoardHr`,
    payload as any
  );

  return response.data;
};

export const getEmployeeDetails = async (id: any) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/hr/employeeDetails/${id}`
  );

  return response.data;
};

export const updateEmployeeDetails = async (id: any, data: any) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/api/hr/editOnboardingDetails/${id}`,
    data
  );

  return response.data;
};

export const createOrganization = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/subscriber/createOrganization`,
    payload
  );

  return response.data;
};

export const uploadOrganizationLogo = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/subscriber/updateOrganizationLogo`,
    payload
  );

  return response.data;
};

export const getOrganizationDetails = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/subscriber/getOrganizationDetail`
  );
  return response.data;
};

export const updateOrganization = async (payload: any) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/api/subscriber/updateOrganizationDetail`,
    payload
  );

  return response.data;
};

export const updateOrganizationLogoUpdate = async (payload: any) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_URL}/api/subscriber/updateOrganizationLogo`,
    payload
  );

  return response.data;
};

export const validateOrgDomain = async (payload: any) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/subscriber/validateSubdomain`,
    payload
  );

  return response.data;
};
