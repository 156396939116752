import BannerImage from "../../../../assets/img/banner-images/background_6.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./leave-management-page-styles";

import circleimg2 from "../../../../assets/img/servicebanner/service-inside-img/6-LM-MinimizePaperwork.svg";
import circleimg3 from "../../../../assets/img/servicebanner/service-inside-img/7-LM-Fix payrolls.svg";
import circleimg1 from "../../../../assets/img/servicebanner/service-inside-img/8-LM-Atomateapproval.svg";

export const LeaveManagement = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>Leave Management</h2>
                <p>
                  Docktimers' centralized leave management solution allows you
                  to track and approve leaves while on the road. Untangle your
                  organization's leave management with our leave management
                  system.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <div className="main">
          <div className="time-section">
            <div className="container">
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg1} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Automate Approvals</h2>
                    </div>
                  </div>
                  <div className="col-lg-6 right-content">
                    <div className="section-content">
                      <p>
                        Automating the approval process not only establishes a
                        consistent, simplified approach but may also reduce
                        confusion and aid with tracking and auditing. Dates
                        might be permanently or temporarily blacklisted. This
                        protocol may be completed in a short span of time and
                        results in the employee receiving a clear record of
                        their authorized leave without the need for lengthy
                        email threads.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg2} alt="" />
                  </div>
                </div>
                <div className="row responsive">
                  <div className="col-lg-6">
                    <div className="section-content-left">
                      <p>
                        Traditional methods of dealing with corporate and
                        personnel paperwork are not only inefficient but also
                        time-consuming. Manage all leave applications
                        effectively and with little paperwork by automating and
                        streamlining the process.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 maim-content-head-right">
                    <div className="content-head">
                      <h2>Minimize Paperwork</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg3} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Fix payroll errors</h2>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-content">
                      <p>
                        The detailing and the accuracy provided by our system
                        will help you eliminate the likelihood of human mistakes
                        by preserving clear and concise employment-related data,
                        financial records of employee gross earnings, payroll
                        deductions, net pay and many more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </StaticSkeleton>
  );
};
