import toast from "react-hot-toast";

export const successNotify = (toastMessage: string) =>
  toast.success(toastMessage, { duration: 6000 });

export const errorNotify = (toastMessage: string) => toast.error(toastMessage);

export const infoNotify = (toastMessage: string) =>
  toast(toastMessage, {
    icon: "ℹ️",
  });

export const warningNotify = (toastMessage: string) =>
  toast(toastMessage, {
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
    },
  });
