import { Card, Typography } from "@mui/material";
import { isString } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate, useParams } from "react-router-dom";
import cancelAnimationData from "../../../../assets/animated-files/cancel-animation.json";
import celebrationData from "../../../../assets/animated-files/check-mark.json";
import {
  errorNotify,
  successNotify,
} from "../../../components/notify-components/notify/notify.ui";
import { APP_ROUTE } from "../../../constants/routes.constants";
import useDidMountEffect from "../../../rest/actions/rest-hook.actions";
import { verifyEmail } from "../../../rest/authentication.rest";

const VerifyEmailFromToken = () => {
  const [verifyResponse, setVerifyResponse] = useState<{
    error: number;
    message: string;
    requireSetPassword?: boolean;
    status: number;
  }>();

  const { verifyToken } = useParams();
  const navigate = useNavigate();

  const handleVerifyEmail = useCallback(async (employeeId: string) => {
    try {
      const res = await verifyEmail(employeeId);
      console.log("res:", res);
      setVerifyResponse(res);
      if (res.status === 1) {
        successNotify(res.message);
      }
    } catch {
      errorNotify("Unable to verify email, having trouble with server");
    }
  }, []);

  useEffect(() => {
    if (verifyResponse?.requireSetPassword) {
      navigate(APP_ROUTE.STATIC.VERIFY_CONFIRMATION_CODE);
    }
  }, [navigate, verifyResponse?.requireSetPassword]);

  useDidMountEffect(() => {
    if (isString(verifyToken)) {
      handleVerifyEmail(verifyToken);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        raised
        style={{
          paddingLeft: 32,
          paddingRight: 32,
          display: "flex",
          alignItems: "center",
          marginTop: 60,
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData:
              verifyResponse?.status === 1
                ? celebrationData
                : cancelAnimationData,
          }}
          height={"100%"}
          width={90}
        />

        <Typography variant="h6" className="text-dark font-weight-bold">
          {verifyResponse?.message}
        </Typography>
      </Card>
    </div>
  );
};

export default VerifyEmailFromToken;
