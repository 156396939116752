// Get item from local storage
function getFromLocalStorage<T>(key: string): T | null {
  try {
    const item = localStorage.getItem(key);
    if (item === null) {
      throw new Error(`Item with key '${key}' not found in local storage.`);
    }
    return JSON.parse(item) as T;
  } catch (error) {
    console.error(`Error retrieving item from local storage: ${error}`);
    return null;
  }
}

// Set item in local storage
function setToLocalStorage<T>(key: string, value: T): void {
  try {
    const item = JSON.stringify(value);
    localStorage.setItem(key, item);
  } catch (error) {
    console.error(`Error setting item in local storage: ${error}`);
  }
}

// Update item in local storage
function updateInLocalStorage<T>(key: string, updateFn: (item: T) => T): void {
  try {
    const existingItem = getFromLocalStorage<T>(key);
    if (existingItem !== null) {
      const updatedItem = updateFn(existingItem);
      setToLocalStorage(key, updatedItem);
    }
  } catch (error) {
    console.error(`Error updating item in local storage: ${error}`);
  }
}

// Remove item from local storage
function removeFromLocalStorage(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing item from local storage: ${error}`);
  }
}



export {
  getFromLocalStorage, removeFromLocalStorage, setToLocalStorage,
  updateInLocalStorage
};
