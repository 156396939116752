import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { Password } from "primereact/password";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import DocktimerLogo from "../../../../assets/img/docktimer/DockTimer_Logo.svg";
import login_imgage from "../../../../assets/img/docktimer/Login-page-img.svg";
import { LOCAL_STATE, useAppStore } from "../../../../persist-store/app-store";
import {
  errorNotify,
  successNotify,
} from "../../../components/notify-components/notify/notify.ui";
import { ErrorTextfield } from "../../../components/notify-components/warn-textfield/warn-textfield";
import { APP_ROUTE } from "../../../constants/routes.constants";
import { appLogin } from "../../../rest/authentication.rest";
import { loginDefaultValues } from "../../../utils/form-utils";
import { loginFromSchema } from "../../../utils/schema-validations/schema-validations-utils";

import { Box, Dialog } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useTranslation } from "react-i18next";
import BackDropLoader from "../../../components/elements/loader/back-drop-loader.component";
import { User } from "../../../rest/rest-interfaces/user.interface";
import { getSubDomain } from "../../../rest/user-profile.rest";
import {
  fetchSetCurrentUser,
  replaceSubDomain,
} from "../../../utils/common.utils";
import { setToLocalStorage } from "../../../utils/local-storage/local-storage.util";
import OrganizationPage from "../../organization/organisation";
import { Wrapper } from "./login-style";

export const LoginPage = () => {
  const { t } = useTranslation();
  const [loginForm, setLoginForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [askOrg, setAskOrg] = useState(false);
  const navigate = useNavigate();
  const { setAccessToken, setIsAuthenticated, setCurrentUser } = useAppStore();

  const openForgotPwd = () => {
    setLoginForm(false);
    navigate(APP_ROUTE.STATIC.FORGOT_PASSWORD);
  };

  const goToSignup = () => {
    navigate(APP_ROUTE.STATIC.SIGN_UP);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: loginDefaultValues,
    resolver: yupResolver(loginFromSchema),
  });

  function reloadWithPath(path: string) {
    window.location.href = window.location.origin + path;
    setTimeout(() => {
      window.location.reload();
    }, 2000); // TODO This is not a proper way a temporary patch to load url properly
  }

  const onSubmit = async function (data: { email: string; password: string }) {
    setIsLoading(true);

    try {
      const loginDetails = await appLogin(data);
      console.log("loginDetails:", loginDetails);

      if (loginDetails?.result.token) {
        setAccessToken(loginDetails.result.token);

        try {
          const subDomain = await getSubDomain(loginDetails?.result.token);
          const hasSubDomain = Object.values(subDomain).length > 0;
          if (subDomain) {
            const resp = await fetchSetCurrentUser();
            setCurrentUser(resp as User);
            setIsAuthenticated(true);
            setToLocalStorage(
              LOCAL_STATE.SUB_DOMAIN,
              loginDetails.result.redirect_to
            );
            if (!hasSubDomain) {
              setAskOrg(true);
              // window.location.href = APP_ROUTE.ORG_CREATE;
              // window.location.reload();
            } else {
              const redirectingPath = replaceSubDomain(
                loginDetails.result.redirect_to,
                APP_ROUTE.DASHBOARD
              );
              successNotify("You have successfully logged in");
              reloadWithPath(`/${redirectingPath}`);
            }
          }
        } catch (err) {
          const errorMessage = "Failed to get subdomain.";
          return errorNotify(errorMessage);
        }
      }
    } catch (err) {
      const errorMessage = (err as AxiosError)?.message || "An error occurred.";
      setIsAuthenticated(false);
      return errorNotify(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <BackDropLoader />;
  }

  return (
    <Wrapper>
      {/* <!-- ======= login Section ======= --> */}
      <div className="login-container">
        <section id="login" className="login">
          <div className="container loginpage" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 login-image">
                <img className="side-img" src={login_imgage} alt="" />
              </div>
              <div className="col-lg-6 col-md-12 formpad">
                <div className="back-color">
                  <div className="text-center">
                    <div className="Logo-Name">
                      <Link to={"/home"}>
                        <img alt="" src={DocktimerLogo} />
                      </Link>
                      <h3>
                        {" "}
                        <span>{t("label.dock-timer")}</span>{" "}
                      </h3>
                    </div>
                    {loginForm && <h3>{t("label.login-your-account")}</h3>}
                    {!loginForm && <h3>{t("label.forgot-password")}</h3>}
                  </div>

                  {loginForm && (
                    <form
                      className="login-form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-group">
                        <label htmlFor="name">{t("label.email")}</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => {
                            return (
                              <input
                                className="form-control"
                                type="text"
                                placeholder={t("label.enter-email") as string}
                                {...field}
                              />
                            );
                          }}
                        />
                        {errors.email?.message && (
                          <ErrorTextfield>
                            {t("message.field-is-required", {
                              field: t("label.username"),
                            })}
                          </ErrorTextfield>
                        )}
                      </div>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label htmlFor="password">{t("label.password")}</label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Password
                                type="password"
                                placeholder={
                                  t("label.please-enter-password") as string
                                }
                                feedback={false}
                                toggleMask
                                {...field}
                              />
                            );
                          }}
                        />
                        {errors.password?.message && (
                          <ErrorTextfield>
                            {" "}
                            {t("message.field-is-required", {
                              field: t("label.password"),
                            })}
                          </ErrorTextfield>
                        )}
                      </div>
                      <div className="forgot-pass d-flex justify-content-between align-items-center">
                        <div>
                          <FormGroup>
                            <FormControlLabel
                              className="password"
                              control={<Checkbox defaultChecked />}
                              label={t("label.remember")}
                            />
                          </FormGroup>
                        </div>
                        <h6
                          className="password m-0 d-flex"
                          onClick={openForgotPwd}
                        >
                          {t("label.forgot-password")}?
                        </h6>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="submit-btn">
                          {t("label.login")}
                        </button>
                      </div>
                      <div className="signuplink text-center mt-3">
                        <p>
                          {t("label.dont-have-an-account")}?{" "}
                          <span onClick={goToSignup}>{t("label.sign-up")}</span>{" "}
                        </p>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End login Section */}
        {askOrg && (
          <Dialog fullScreen open>
            <Box p={4}>
              <OrganizationPage manage={false} />
            </Box>
          </Dialog>
        )}
      </div>
    </Wrapper>
  );
};
