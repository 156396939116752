import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";

export const Wrapper = styled.div`



.p-inputtext, .p-component, .p-password-input {
    border-radius: 24px !important;
    height: 44px;
    width: 100%;
}
// ------------------------------------------
  // # login
  // --------------------------------------------------------------*/
  .logo{
    background:  ${styleConstants.PRIMARY_COLOR};
    display: flex;
    justify-content: center;
    padding: 13px;
    margin-bottom: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img{
        width: 55px;
    }
 }
 .textColor{
  color: ${styleConstants.PRIMARY_COLOR};
 }

  .login-form {
    padding: 15px 8px;
    .form-group {
        display: flex;
        flex-direction: column;
      .form-control {
        height: 44px;
        border-radius: 25px;
      }
    }
  }

  .password-input {
    input{
        height: 44px;
        width:100%;
        border-radius: 25px;
    }
  }

  .main-logo-text {
    font-weight: 900;
    letter-spacing: 2px;
    color: ${styleConstants.PRIMARY_COLOR};
    text-shadow: 1px 1px #000;

    span {
      color: ${styleConstants.SECONDARY_COLOR};
    }
  }

  #login {
    margin-bottom: 60px;
  }

  .login-container{
    // background: rgb(50,50,187);
    // background: linear-gradient(8deg, rgba(50,50,187,1) 35%, rgba(167,108,50,1) 100%);
    // padding: 10px;
    // height: 100vh;
  }

  .loginpage {
    border-radius: 60px;
    margin-bottom: 20px;
    border-radius: 60px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    overflow: auto;
    padding: 1em 2em;
    display: table;
  

    h3 {
      font-size: 26px;
      color: ${styleConstants.PRIMARY_COLOR};
      font-weight: 900;
      margin-bottom: 35px;
      line-height: 1.21;
      letter-spacing: 2px;
    }
    h2 {
      font-size: 36px;
      text-align: center;
      color: ${styleConstants.PRIMARY_COLOR};
      font-weight: 900;
      line-height: 1.21;
      letter-spacing: 2px;
    }

    .side-img {
      width: 100%;
    }

   
  
    .back-color {
      width: 100%;
      border-bottom: 3px solid ${styleConstants.PRIMARY_COLOR};
      padding: 15px;
      background: ${styleConstants.WHITE_COLOR};
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .google-button {
      position: relative;
      height: 62px;
      padding: 0 0 0 50px;
      width: 100%;
      box-shadow: none;
      -webkit-appearance: none;
      outline: none;
      border-radius: 16px;
      border: solid 2px #f2f1f2;
      background: white;
      background-color: ${styleConstants.WHITE_COLOR};
      color: #7b7b91;
      font-weight: 500;
      font-size: 18px;
    }

    .google-button .google-button-icon {
      height: 38px;
      position: absolute;
      left: 14px;
      top: 10px;
      bottom: 2px;
      border-radius: 2px;
      background: ${styleConstants.WHITE_COLOR};
    }

    .signuplink {
      p {
        font-weight: 600;
        color: ${styleConstants.PRIMARY_COLOR};
        cursor:pointer;
      }

      span {
        color: ${styleConstants.SECONDARY_COLOR};
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
      }
    }

     .submit-btn {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 28px 11px 28px;
      border-radius: 50px;
      transition: 0.5s;
      color: ${styleConstants.WHITE_COLOR};
      background: ${styleConstants.PRIMARY_COLOR};
      border: 2px solid ${styleConstants.WHITE_COLOR};
    }
  
     .submit-btn:hover {
      background: #fff;
      color: ${styleConstants.SECONDARY_COLOR};
      border: 2px solid ${styleConstants.PRIMARY_COLOR};
    }



  .login .info {
    border-top: 3px solid ${styleConstants.PRIMARY_COLOR};
    border-bottom: 3px solid ${styleConstants.PRIMARY_COLOR};
    padding: 30px;
    background: ${styleConstants.WHITE_COLOR};
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  }

  .login .info i {
    font-size: 20px;
    color: ${styleConstants.PRIMARY_COLOR};
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .login .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${styleConstants.PRIMARY_COLOR};
  }

  .login .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
  }

  .login .info .email p {
    padding-top: 5px;
  }

  .login .info .social-links {
    padding-left: 60px;
  }

  .login .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: ${styleConstants.WHITE_COLOR};
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }

  .login .info .social-links a:hover {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

  .login .info .email:hover i,
  .login .info .address:hover i,
  .login .info .phone:hover i {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

 

  .login .login-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }

  .login .login-form .error-message {
    display: none;
    color: ${styleConstants.WHITE_COLOR};
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }

  .login .login-form .error-message br+br {
    margin-top: 25px;
  }

  .login .login-form .sent-message {
    display: none;
    color: ${styleConstants.WHITE_COLOR};
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }

  .login .login-form .loading {
    display: none;
    background: ${styleConstants.WHITE_COLOR};
    text-align: center;
    padding: 15px;
  }

  .login .login-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }

  .vi__wrapper {
    width: 100%;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }
  .character {
    border: none;
    font-size: 20px;
    border-radius: 8px;

    color: #444;
    background-color: #f6f5fa;
    box-shadow: 0 2px 0 #e4e2f5;
  

    box-shadow: 0 2px 0 ${styleConstants.PRIMARY_COLOR};
  }
  
  .login .login-form .form-group {
    margin-bottom: 20px;
  }

  .login .login-form label {
    padding-bottom: 8px;
  }

  .login .login-form input,
  .login .login-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }

  .login .login-form input:focus,
  .login .login-form textarea:focus {
    border-color: ${styleConstants.PRIMARY_COLOR};
  }

 

  .login .login-form textarea {
    padding: 10px 12px;
  }

 

  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  .form-group,
  .form-check,
  .password {
    color: ${styleConstants.PRIMARY_COLOR};
    font-weight: 600;
    cursor: pointer;
  }
.requiredmessage {
  color: red;
  font-size: 14px;
  margin-left: 14px;
}
.required {
  color: red;
}
    
@media (min-width: 320px) and (max-width: 767px){
    .main-logo {
       margin-top: 1rem;
   }
   .signuppage{
       .formpad {
           padding: 10px;
       }
       .back-color{
           margin-bottom: 20px;
       }
   }
}

    `;
