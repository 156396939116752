import styled from "styled-components";
import { styleConstants } from "../../../styles/StyleConstants";

export const Wrapper = styled.div`
  .main-wrap {
    width: calc(100% - 15%);
    margin-left: 15%;
    padding: 50px 20px;
    background: ${styleConstants.TERTIARY_COLOR};
    height: 100vh;
  }
  .MuiTypography-root{
    font-weight: 600;
  }
  @media (max-width: 1024px) {
    .main-wrap {
      width: calc(100% - 0%);
      margin-left: 0;
      padding: 20px;
      background: ${styleConstants.TERTIARY_COLOR};
    }
  }

  .profile-hover {
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .company-name {
    display: block;
    color: #000;
    font-weight: bold;
  }
  .company-container {
    transition: 0.2s;
    transition-timing-function: ease-in-out;
    background: #fff;
    cursor: pointer;
    border-radius: 15px;
    padding: 0 0 0 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .company-container {
    transition: 0.2s;
    transition-timing-function: ease-in-out;
    background: #fff;
    cursor: pointer;
    border-radius: 15px;
    padding: 0 0 0 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`;
