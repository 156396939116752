import axios from "axios";
import { LOCAL_STATE } from "../../persist-store/app-store";
import { getFromLocalStorage } from "../utils/local-storage/local-storage.util";

let accessToken = "";

accessToken = getFromLocalStorage(LOCAL_STATE.ACCESS_TOKEN) as string

accessToken &&
  axios.interceptors.request.use(
    (config) => {
      console.log("config:", config.baseURL);
      if (config && config.headers) {
        config.headers["authorization-token"] = `${accessToken}`;
      }

      return config;
    },
    (error) => {
      console.log('error:', error);
      return Promise.reject(error);
    }
  )

export default axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api/`,
});
