import { NavbarComponent } from "../navbar-component/navbar-component";
import { FooterComponent } from "../footer-component/footer-component";
import { FunctionComponent, useEffect, useRef } from "react";
import { StaticSkeletonProps } from "./static-skeleton.interfaces";
import { Box } from "@mui/material";

const StaticSkeleton: FunctionComponent<StaticSkeletonProps> = ({
  children,
}: StaticSkeletonProps) => {
  const scrollRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current &&
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
  }, []);

  return (
    <Box ref={scrollRef}>
      <NavbarComponent />
      {children}
      <FooterComponent />
    </Box>
  );
};

export default StaticSkeleton;
