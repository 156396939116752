import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";

interface WrapperProps {
  image: string;
}

export const Wrapper = styled.div<WrapperProps>`
  /*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

  .SingleCol__DecoratorBlob1 {
    pointer-events: none;
    position: absolute;
    right: -103px;
    top: 0px;
    z-index: -20;
    height: 14rem;
    width: 14rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__DecoratorBlob2 {
    pointer-events: none;
    position: absolute;
    left: -103px;
    top: 0px;
    z-index: -20;
    height: 14rem;
    width: 14rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__DecoratorBlob3 {
    pointer-events: none;
    position: absolute;
    right: -358px;
    top: 0px;
    z-index: -20;
    height: 14rem;
    width: 14rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__DecoratorBlob4 {
    pointer-events: none;
    position: absolute;
    left: -350px;
    top: 0px;
    z-index: -20;
    height: 14rem;
    width: 14rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__DecoratorBlob5 {
    pointer-events: none;
    position: absolute;
    right: -358px;
    top: 0px;
    z-index: -20;
    height: 14rem;
    width: 14rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__DecoratorBlob6 {
    pointer-events: none;
    position: absolute;
    left: -350px;
    top: 0px;
    z-index: -20;
    height: 14rem;
    width: 14rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__Square1 {
    pointer-events: none;
    position: absolute;
    left: 55px;
    top: 170px;
    z-index: -20;
    height: 14rem;
    width: 6rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__Square2 {
    pointer-events: none;
    position: absolute;
    right: 25px;
    top: 250px;
    z-index: -20;
    height: 14rem;
    width: 6rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__Square3 {
    pointer-events: none;
    position: absolute;
    left: -12px;
    top: 225px;
    z-index: -20;
    height: 14rem;
    width: 6rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__Square4 {
    pointer-events: none;
    position: absolute;
    right: 50px;
    top: 250px;
    z-index: -20;
    height: 14rem;
    width: 6rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  .SingleCol__Square5 {
    pointer-events: none;
    position: absolute;
    left: 25px;
    top: 240px;
    z-index: -20;
    height: 14rem;
    width: 6rem;
    color: rgb(79 209 197 / 1);
    opacity: 0.15;
  }
  #hero {
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat, no-repeat, repeat;
    overflow: hidden;
    background-position: bottom;

    .hero-img {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 440px;
      }
    }

    h2 {
      margin: 0 0 10px 0;
      font-size: 66px;
      font-weight: 700;
      line-height: 70px;
      color: ${styleConstants.WHITE_COLOR};
    }

    h6 {
      color: ${styleConstants.WHITE_COLOR};
      font-size: 1.4em;
      line-height: 1.1;
      font-weight: 400;
      letter-spacing: 0.02em;
    }

    .main-tag {
      width: 0ch;
      overflow: hidden;
      white-space: nowrap;
      animation: text 10s steps(32) infinite alternate;
      border-right: 3px solid ${styleConstants.SECONDARY_COLOR};
    }

    @keyframes text {
      0% {
        width: 0ch;
      }

      50% {
        width: 32ch;
      }
    }

    .main-tag {
      color: ${styleConstants.SECONDARY_COLOR};
    }

    h4 {
      color: ${styleConstants.WHITE_COLOR};
      font-size: 24px;
      letter-spacing: 0.02em;
    }

    h3 {
      font-size: 36px;
      color: ${styleConstants.WHITE_COLOR};
      letter-spacing: 0.02em;
      font-weight: 600;
      letter-spacing: 0.02em;
    }

    strong {
      font-size: 35px;
    }

    span {
      color: ${styleConstants.SECONDARY_COLOR};
    }

    p {
      color: ${styleConstants.WHITE_COLOR};
      font-size: 20px;
      line-height: 1.5;
      max-width: 33em;
      margin: 0.7em auto 0.5em;
      margin-top: 7rem;
      font-weight: 600;
      letter-spacing: 0.02em;
    }

    h6 {
      font-weight: 600;
      letter-spacing: 0.02em;
    }

    .banner-section {
      margin-top: 10rem;

      &::before {
        width: 80vw;
        height: 80vw;
        bottom: -40vw;
        left: 21vw;
      }
    }

    .formwrapper {
      position: relative;
      width: 32em;
      margin-bottom: 0;
    }

    .textfield {
      height: 2.4em;
      padding: 0 10.67em 0 1.67em;
      border: 1px solid rgba(56, 49, 68, 0.1);
      border-radius: 0.8em;
      background-color: ${styleConstants.WHITE_COLOR};
      box-shadow: 1px 1px 0.5em 0 rgb(56 49 68 / 10%);
      font-size: 1.2em;
      line-height: 1;
      display: block;
      width: 100%;
      outline: none;
      color: #333;
      vertical-align: middle;
    }

    .trailmainform {
      position: absolute;
      left: auto;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 28px 11px 28px;
      border-radius: 10px;
      transition: 0.5s;
      color: ${styleConstants.WHITE_COLOR};
      border: 2px solid ${styleConstants.WHITE_COLOR};
      color: ${styleConstants.WHITE_COLOR};
      background: ${styleConstants.PRIMARY_COLOR};
    }

    .btn-get-started {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 28px 11px 28px;
      border-radius: 50px;
      transition: 0.5s;
      margin: 10px 0 0 0;
      color: ${styleConstants.WHITE_COLOR};
      border: 2px solid ${styleConstants.WHITE_COLOR};
    }

    .btn-get-started:hover {
      background: #fff;
      color: #000;
      border: 2px solid #209dd8;
    }

    .btn-watch-video {
      font-size: 16px;
      display: flex;
      align-items: center;
      transition: 0.5s;
      margin: 10px 0 0 25px;
      color: ${styleConstants.WHITE_COLOR};
      line-height: 1;
    }

    .btn-watch-video i {
      line-height: 0;
      color: ${styleConstants.WHITE_COLOR};
      font-size: 32px;
      transition: 0.3s;
      margin-right: 8px;
    }

    .btn-watch-video:hover i {
      color: ${styleConstants.PRIMARY_COLOR};
    }

    .animated {
      animation: up-down 2s ease-in-out infinite alternate-reverse both;
    }
  }

  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(-10px);
    }
  }

  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(-10px);
    }
  }

  /*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
  .aboutback-Color {
    // background: #FAFBFE;
    padding: 50px 0 100px;
    margin-top: 2rem;
  }

  .box-area {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .box-area li {
    position: absolute;
    display: block;
    list-style: none;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 20s linear infinite;
    bottom: -150px;
  }

  .box-area li:nth-child(1) {
    left: 86%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    border-radius: 50%;
  }

  .box-area li:nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    animation-delay: 1.5s;
    animation-duration: 10s;
  }

  .box-area li:nth-child(3) {
    left: 70%;
    width: 100px;
    height: 100px;
    animation-delay: 5.5s;
  }

  .box-area li:nth-child(4) {
    left: 42%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 15s;
  }

  .box-area li:nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
  }

  .box-area li:nth-child(6) {
    left: 15%;
    width: 110px;
    height: 110px;
    animation-delay: 3.5s;
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }

    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }

  .about .content {
    background-color: #f6f9ff;
    padding: 40px;
  }

  .about-content-right {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      letter-spacing: 0;
      color: #000;
      line-height: 1.7;
      font-weight: 600;
      margin-bottom: 25px;
      text-align: justify;
    }
    .about-title-head {
      .heading {
        text-align: center;
      }

      h2 {
        color: ${styleConstants.DARK_GREY_COLOR};
        padding: 5px 0;
        font-weight: 900;
        transition: all 0.3s ease-in-out;
      }
      p {
        color: ${styleConstants.LIGHT_GREY_COLOR};
      }
    }
  }

  /*--------------------------------------------------------------
# services
--------------------------------------------------------------*/

  .service-section {
    position: relative;
    margin-bottom: 20px;

    .service-curve-img {
      position: absolute;
      height: 141rem;
      width: 111%;
    }

    .content-top {
      margin-top: 2rem;
    }

    h3 {
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: -0.03em;
    }

    p {
      margin: 0.7em auto 0.5em;
      flex: 1;
      letter-spacing: 0;
      color: ${styleConstants.LIGHT_GREY_COLOR};
      line-height: 1.7;
      font-weight: 600;
      text-align: justify;
    }

    .sectionstabs {
      position: relative;
      max-width: 1440px;
      margin-right: auto;
      margin-left: auto;
      padding: 2em 1em;

      .service-text {
        .service-icon-head {
          display: flex;
          align-items: center;

          .service-svg-icon {
            fill: ${styleConstants.PRIMARY_COLOR_LIGHT};
            cursor: pointer;
            margin-right: 20px;

            &:hover {
              fill: ${styleConstants.SECONDARY_COLOR};
              transition: 1s;
            }
          }

          h3 {
            margin-bottom: 0;
            a {
              color: ${styleConstants.DARK_GREY_COLOR};
              &:hover {
                color: ${styleConstants.SECONDARY_COLOR};
              }
            }
          }
        }
      }
    }

    .section-title {
      margin-top: 1rem;
    }

    .service-hero-img {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .service-hero-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .readmore-btn {
      margin-top: 2rem;

      .cta {
        position: relative;
        margin: auto;
        padding: 20px 22px;
        transition: all 0.2s ease;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          border-radius: 28px;
          background: ${styleConstants.PRIMARY_COLOR_LIGHT};
          width: 56px;
          height: 56px;
          transition: all 0.3s ease;
        }

        span {
          position: relative;
          font-size: 16px;
          line-height: 18px;
          font-weight: 900;
          color: ${styleConstants.PRIMARY_COLOR};
          letter-spacing: 0.25em;
          text-transform: uppercase;
          vertical-align: middle;
        }

        svg {
          position: relative;
          top: 0;
          margin-left: 10px;
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: ${styleConstants.PRIMARY_COLOR_LIGHT};
          stroke-width: 2;
          transform: translateX(-5px);
          transition: all 0.3s ease;
        }
      }

      .cta:active {
        transform: scale(0.96);
      }

      .cta:hover svg {
        transform: translateX(0);
        stroke: ${styleConstants.WHITE_COLOR};
      }

      .cta:hover span {
        color: ${styleConstants.WHITE_COLOR};
      }

      .cta:hover:before {
        width: 100%;
        background: ${styleConstants.PRIMARY_COLOR};
      }
    }

    .image-card {
      img {
        transition: 0.4s ease;
      }
    }

    .service-hero-img:hover .image-card img {
      -webkit-transform: scale(1.08);
      transform: scale(1.08);
    }
  }

  /*--------------------------------------------------------------
# services
--------------------------------------------------------------*/
  @media (min-width: 320px) and (max-width: 767px) {
    .service-section .service-curve-img {
      width: 100%;
    }
    .box-area li {
      display: none;
    }

    #hero h2 {
      font-size: 32px;
      line-height: 32px;
    }
    #hero {
      .banner-section {
        margin-top: 7rem;
      }
      .main-tag {
        font-size: 18px;
      }
      p {
        margin-top: 0;
      }
    }
    .section {
      .time_image {
        width: 100%;
        height: 400px;
      }
    }
    .about-content-right {
      padding-left: 0;
      .about-title-head p {
        padding: 15px;
      }
    }

    .service-section {
      .image-card {
        margin-bottom: 35px;
      }
      .sectionstabs .service-text .service-icon-head {
        h3 a {
          font-size: 24px;
        }
        .service-svg-icon {
          width: 50px;
        }
      }

      .content-top {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 991px) {
    #hero {
      text-align: center;
      .animated {
        -webkit-animation: none;
        animation: none;
      }

      .hero-img {
        text-align: center;
      }

      .hero-img img {
        width: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;

      h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
      }

      .hero-img img {
        width: 70%;
      }
    }
  }

  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;

      .btn-get-started {
        font-size: 16px;
        padding: 10px 24px 11px 24px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    #hero {
      h2 {
        font-size: 45px;
      }
      p {
        margin-top: 2rem;
      }
      .banner-section {
        margin-top: 7rem;
      }
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`;
