import * as yup from "yup";

export const firstStep = yup.object({
  logo: yup.mixed().required("Logo is required"),
  name: yup.string().required("Organization Name is required"),
  organization_email: yup.string().email("Enter valid email address").required("Organization Email is required"),
  about_us: yup.string().required("About Organization is required"),
  specialities: yup.string().required("Specialities About Organization is required")
});

export const secondStep = yup.object({
  'line_one': yup.string().required("Line one is required"),
  'line_two': yup.string().optional(),
  'city': yup.string().required("City is required"),
  'state': yup.string().required("State is required"),
  'country': yup.string().required("Country is required"),
  'zipcode': yup.number().required("Zipcode is required"),
})

export const thirdStep = yup.object({
  subdomain: yup.string().min(3).required("Organization Domain is required"),
})