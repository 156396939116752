import BannerImage from "../../../../assets/img/banner-images/background_5.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./hr-management-page-styles";

import circleimg1 from "../../../../assets/img/servicebanner/service-inside-img/14-HR-Appropriate Hiring.svg";
import circleimg2 from "../../../../assets/img/servicebanner/service-inside-img/15-Hr-Employeeonboarding.svg";
import circleimg3 from "../../../../assets/img/servicebanner/service-inside-img/16-Hr-Automated Remainders.svg";

export const HRManagement = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>HR MANAGEMENT</h2>
                <p>
                  The HR solution feature will allow you to manage and structure
                  your staff data. It may also assist you in talent management,
                  onboarding employees easily, and identifying the best match
                  for the firm to reach the targeted heights and sustain
                  employee-employer relationships longer.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <div className="main">
          <div className="time-section">
            <div className="container">
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg1} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Appropriate Hiring</h2>
                    </div>
                  </div>
                  <div className="col-lg-6 right-content">
                    <div className="section-content">
                      <p>
                        Choosing the ideal applicant for a specific post can be
                        a time-consuming process. An in-depth examination of the
                        present employee, as well as maintaining track of them,
                        can provide a good sense of what sort of skill set is
                        necessary to hire in order for the firm to develop.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg2} alt="" />
                  </div>
                </div>
                <div className="row responsive">
                  <div className="col-lg-6">
                    <div className="section-content-left">
                      <p>
                        Integrate your new employees quickly and easily without
                        wasting much time or effort. Docktimer’s automatic tools
                        will make it appear to be a cakewalk where you can
                        easily onboard your employees and keep the workflow
                        smooth.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 maim-content-head-right">
                    <div className="content-head">
                      <h2>Employee Onboarding</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg3} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Automated Remainders</h2>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-content">
                      <p>
                        It’s natural to forget things when we do multiple tasks
                        at once our Automized system reminds you via
                        notifications to distribute the employee newsletter,
                        memos, and reminders about upcoming leave requests so
                        that everyone receives the message at once.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </StaticSkeleton>
  );
};
