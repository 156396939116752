// Set a value in session storage
export const setSessionStorage = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Failed to set session storage:', error);
  }
};

// Get a value from session storage
export const getSessionStorage = (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error('Failed to get session storage:', error);
    return null;
  }
};

// Update a value in session storage
export const updateSessionStorage = (key:string, newValue:any) => {
  try {
    const existingValue = getSessionStorage(key);
    if (existingValue) {
      const updatedValue = { ...existingValue, ...newValue };
      setSessionStorage(key, updatedValue);
    }
  } catch (error) {
    console.error('Failed to update session storage:', error);
  }
};

// Remove a value from session storage
export const removeSessionStorage = (key:string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error('Failed to remove session storage:', error);
  }
};
