import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { styleConstants } from "../../../styles/StyleConstants";

export const MuiCustomTextField = styled(TextField)({
  width: "100%",
  "& input.Mui-disabled": {
    color: `${styleConstants.DARK_GREY_COLOR}`,
  },
  "& label.Mui-focused": {
    color: `#D3D3D3`,
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#ffff",
    borderRadius: "6px",
    border: `#D3D3D3`,
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: "#ffff",
    border: "0px",
    boxSizing: "border-box",
    color: `${styleConstants.DARK_GREY_COLOR}`,
  },
  "& .MuiOutlinedInput-root:hover": {
    backgroundColor: "#ffff",
    boxSizing: "border-box",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    // backgroundColor: "#E5E5E5",
    border: `0px`,
    boxSizing: "border-box",
  },
  "& .MuiFormHelperText-root": {
    color: "#F53938",
  },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: `#D3D3D3`,
  },
  "& .MuiInputBase-root:hover": {
    borderColor: `#D3D3D3`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#D3D3D3 !important`,
  },
});
