
import { FC } from "react";
import { MutatingDots } from "react-loader-spinner";
import { styleConstants } from "../../../../styles/StyleConstants";

export const Loader: FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: "center" , alignItems: 'center', height: "100vh" }}>
                  <MutatingDots
                    height="100"
                    width="100"
                    color={styleConstants.PRIMARY_COLOR}
                    secondaryColor={styleConstants.SECONDARY_COLOR}
                    radius='14'
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
  );
};
