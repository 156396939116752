import CloseIcon from "@mui/icons-material/Close";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PaidIcon from "@mui/icons-material/Paid";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReportIcon from "@mui/icons-material/Report";
import ThreePIcon from "@mui/icons-material/ThreeP";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DocktimerLogo from "../../../../assets/img/docktimer/DockTimer_withoutshadow.svg";
import toggleicon from "../../../../assets/img/docktimer/humburger.svg";
import { APP_ROUTE } from "../../../constants/routes.constants";
import { Wrapper } from "./navbar-component-styles";
export function NavbarComponent() {
  const [staticNavbar, setNavbar] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);

  useEffect(() => {
    changeBackground();
    changeService();
  });

  const changeBackground = () => {
    if (window.scrollY > 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const changeService = () => {
    if (window.scrollY > 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <Wrapper image={toggleicon}>
        <div
          id="header"
          className={staticNavbar ? "staticNavbar active" : "staticNavbar"}
        >
          <div className="top-header">
            <div className="container">
              <nav className="navbar  navbar-expand-lg">
                <Link className="navbar-brand" to={APP_ROUTE.STATIC.HOMEPAGE}>
                  <img src={DocktimerLogo} alt="dockTimer" />
                  <span>DockTimer</span>
                </Link>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav">
                    <li>
                      <Link to={APP_ROUTE.STATIC.HOMEPAGE} className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li className="dropdown">
                      <a>
                        <span>Services</span>
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <ul>
                        <li>
                          <Link to={APP_ROUTE.STATIC.TIME_MANAGE_PAGE}>
                            <MoreTimeIcon />
                            &nbsp;Time Management
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.LEAVE_MANAGE_PAGE}>
                            <ReceiptLongIcon />
                            &nbsp;Leave Management
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.EXPENSE_MANAGE_PAGE}>
                            <PaidIcon />
                            &nbsp;Expense Management
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.HR_MANAGE_PAGE}>
                            <ThreePIcon />
                            &nbsp;HR Solutions
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.REPORT_MANAGE_PAGE}>
                            <ReportIcon />
                            &nbsp;Report Management
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to={APP_ROUTE.STATIC.ABOUT_PAGE}
                        className="nav-link scrollto"
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={APP_ROUTE.STATIC.PRICING}
                        className="nav-link scrollto"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={APP_ROUTE.STATIC.CONTACT_PAGE}
                        className="nav-link scrollto"
                      >
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={APP_ROUTE.STATIC.SIGN_UP}
                        className="getstartbtn scrollto"
                      >
                        Get Started
                      </Link>
                    </li>
                    <li>
                      <Link to={APP_ROUTE.STATIC.LOGIN} className="nav-link scrollto">
                        <LoginIcon /> &nbsp; Login
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>

          <div className="responsive-view">
            <nav className="navigation">
              <Link className="brand-name" to={APP_ROUTE.STATIC.HOMEPAGE}>
                <img src={DocktimerLogo} alt="dockTimer" />
                <span>DockTimer</span>
              </Link>
              <button
                className="hamburger"
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
              >
                {/* icon from heroicons.com */}
                <div>{!isNavExpanded ? <MenuIcon /> : <CloseIcon />}</div>
              </button>
              <div
                className={
                  isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                }
              >
                <ul className="listUl">
                  <li>
                    <Link to={APP_ROUTE.STATIC.HOMEPAGE} className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="dropdown">
                    <a
                      onClick={() => {
                        setServiceOpen(!serviceOpen);
                      }}
                    >
                      <span>Services &nbsp;</span>
                      <i
                        className={
                          !serviceOpen
                            ? "fa fa-chevron-down"
                            : "fa fa-chevron-up"
                        }
                        aria-hidden="true"
                      ></i>
                    </a>
                    <div
                      className={
                        serviceOpen ? "services servicesList" : "services"
                      }
                    >
                      <ul className="expandedList">
                        <li>
                          <Link to={APP_ROUTE.STATIC.TIME_MANAGE_PAGE}>
                            <MoreTimeIcon />
                            &nbsp;Time Management
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.LEAVE_MANAGE_PAGE}>
                            <ReceiptLongIcon />
                            &nbsp;Leave Management
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.EXPENSE_MANAGE_PAGE}>
                            <PaidIcon />
                            &nbsp;Expense Management
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.HR_MANAGE_PAGE}>
                            <ThreePIcon />
                            &nbsp;HR Solutions
                          </Link>
                        </li>
                        <li>
                          <Link to={APP_ROUTE.STATIC.REPORT_MANAGE_PAGE}>
                            <ReportIcon />
                            &nbsp;Report Management
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <Link
                      to={APP_ROUTE.STATIC.ABOUT_PAGE}
                      className="nav-link scrollto"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={APP_ROUTE.STATIC.CONTACT_PAGE}
                      className="nav-link scrollto"
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={APP_ROUTE.STATIC.SIGN_UP}
                      className="getstartbtn scrollto"
                    >
                      Get Started
                    </Link>
                  </li>
                  <li>
                    <Link to={APP_ROUTE.STATIC.LOGIN} className="nav-link scrollto">
                      <LoginIcon /> &nbsp;Login
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
