import { Controller, useForm } from "react-hook-form";
import BannerImage from "../../../../assets/img/banner-images/background_3.png";
// import { ErrorTextfield } from "../../../components/notify-components/warn-textfield/warn-textfield";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { contactUsForm } from "../../../utils/form-utils";
import { Wrapper } from "./contact-us-styles";

export const ContactUsPage = () => {
  const RequiredSpan = () => (
    <span style={{ color: "orange", marginLeft: "0.2rem" }}>*</span>
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: contactUsForm,
  });

  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>CONTACT US</h2>
                <p></p>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-5 col-md-6 mb-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <h4>Location:</h4>
                    <p>Hyderbad Telangana,India</p>
                  </div>
                  <div className="email">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <h4>Email:</h4>
                    <p>
                      <a href="mailto:info@docktimer.com"></a>info@docktimer.com
                    </p>
                  </div>
                  <div className="phone">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <h4>Call:</h4>
                    <p>
                      <a href="tel:(+91 0000 0000 00)"> </a>
                    </p>
                  </div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                    frameBorder={0}
                    style={{ border: 0, width: "100%", height: "290px" }}
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="col-lg-7 mb-5 col-md-6 d-flex align-items-stretch">
                <form className="php-email-form">
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-12">
                      <label htmlFor="name">Organization Name</label>
                      <RequiredSpan />
                      <Controller
                        name="organizationName"
                        control={control}
                        render={({ field }) => {
                          return (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Organization Name"
                              {...field}
                            />
                          );
                        }}
                      />
                      {/* {errors.firstName?.message && (
                                                <ErrorTextfield>
                                                    First name is required
                                                </ErrorTextfield>
                                            )} */}
                    </div>
                    <div className="form-group col-lg-6 col-md-12">
                      <label htmlFor="name">Company Email</label>
                      <RequiredSpan />
                      <Controller
                        name="companyEmail"
                        control={control}
                        render={({ field }) => {
                          return (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Company Email"
                              {...field}
                            />
                          );
                        }}
                      />
                      {/* {errors.firstName?.message && (
                                                <ErrorTextfield>
                                                    First name is required
                                                </ErrorTextfield>
                                            )} */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Subject</label>
                    <RequiredSpan />
                    <Controller
                      name="Subject"
                      control={control}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Subject"
                            {...field}
                          />
                        );
                      }}
                    />
                    {/* {errors.firstName?.message && (
                                                <ErrorTextfield>
                                                    First name is required
                                                </ErrorTextfield>
                                            )} */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Message</label>
                    <RequiredSpan />
                    <Controller
                      name="Message"
                      control={control}
                      render={({ field }) => {
                        return (
                          <textarea
                            className="form-control"
                            rows={10}
                            placeholder="Message"
                            {...field}
                          />
                        );
                      }}
                    />
                    {/* {errors.firstName?.message && (
                                                <ErrorTextfield>
                                                    First name is required
                                                </ErrorTextfield>
                                            )} */}
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
      </Wrapper>
    </StaticSkeleton>
  );
};
