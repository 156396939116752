import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { User, UserId } from "../app/rest/rest-interfaces/user.interface";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
} from "../app/utils/local-storage/local-storage.util";

const initialState = {
  accessToken: "",
  setAccessToken: (accessToken: string) => {},
  setCurrentUser: (currentUser: User | UserId) => {},
  currentUser: {} as User | UserId,
  appLogout: () => {},
  isAuthenticated: false,
  setIsAuthenticated: (_setIsAuthenticate: boolean) => {},
  openSidebar: true
};

export const LOCAL_STATE = {
  ACCESS_TOKEN: "accessToken",
  CURRENT_USER: "currentUser",
  SUB_DOMAIN: "subDomain",
  APP_AUTH: "appAuth",
  RESET_TOKEN: "resetToken",
};

const AppContext = createContext(initialState);

export const useAppStore = () => useContext(AppContext);

const AppStoreProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState(
    getFromLocalStorage(LOCAL_STATE.ACCESS_TOKEN) as string
  );

  const [isAuthenticated, setIsAuthenticate] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);

  const setAccessToken = (accessToken: string) => {
    return setToLocalStorage(LOCAL_STATE.ACCESS_TOKEN, accessToken);
  };
  const setIsAuthenticated = (_setIsAuthenticate: boolean) => {
    return setIsAuthenticate(_setIsAuthenticate);
  };

  const setIsSidebarOpen = (_setIsOpen: boolean) => {
    return setOpenSidebar(_setIsOpen);
  };

  const setCurrentUser = (currentUser: User | UserId) => {
    return setToLocalStorage(LOCAL_STATE.CURRENT_USER, currentUser);
  };

  const currentUser = useMemo(
    () => getFromLocalStorage(LOCAL_STATE.CURRENT_USER) as User | UserId,
    []
  );

  const appLogout = () => {
    return removeFromLocalStorage(LOCAL_STATE.ACCESS_TOKEN);
  };

  const storeContext = useMemo(
    () => ({
      accessToken: token,
      setAccessToken,
      currentUser,
      setCurrentUser,
      appLogout,
      setIsAuthenticated,
      isAuthenticated,
      setIsSidebarOpen,
      openSidebar
    }),
    [currentUser, isAuthenticated, token]
  );

  return (
    <AppContext.Provider value={storeContext}>{children}</AppContext.Provider>
  );
};

export default AppStoreProvider;
