import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppStore } from '../../persist-store/app-store';
import { APP_ROUTE } from '../constants/routes.constants';

const LogoutPage = () => {
  const navigate = useNavigate();
  const {
    appLogout,
  } = useAppStore();

  useEffect(() => {
    localStorage.clear()
    appLogout();
    window.location.replace(APP_ROUTE.STATIC.LOGIN)
  }, []);
  
  return(
    <></>
  )
}

export default LogoutPage;