import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";
interface WrapperProps {
  image: string;
}

export const Wrapper = styled.div<WrapperProps>`
  /*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
  #footer {
    font-size: 14px;
  }
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: #444444;
  }
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: ${styleConstants.PRIMARY_COLOR};
  }
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: ${styleConstants.WHITE_COLOR};
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #209dd8;
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: contain;
    border-top: 1px solid #e1ecff;
    border-bottom: 1px solid #e1ecff;
    padding: 60px 0 30px 0;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    // text-transform: uppercase;
    font-weight: 600;
    color: ${styleConstants.PRIMARY_COLOR};
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: ${styleConstants.PRIMARY_COLOR};
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: ${styleConstants.PRIMARY_COLOR};
    font-size: 12px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }

  .btn-li{
    background-color: #0082CA;
  }
  .btn-fb{
    background-color: #3B5998;
  }
  .btn-tw{
    background-color: #55ACEE;
  }
  .btn-In{
    background-color:#d62976
  }

  #footer .footer-top .footer-links ul a {
    color: ${styleConstants.LIGHT_GREY_COLOR};
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: ${styleConstants.PRIMARY_COLOR};
  }
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    // background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .footer-top .social-links a:hover {
    background: ${styleConstants.SECONDARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
    text-decoration: none;
  }
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: ${styleConstants.WHITE_COLOR};
    background: ${styleConstants.PRIMARY_COLOR};
  }
  #footer .copyright {
    float: left;
  }
  #footer .credits {
    float: right;
    font-size: 13px;
  }
  #footer .credits a {
    transition: 0.3s;
  }
  @media (max-width: 768px) {
    #footer .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    #footer .copyright,
    #footer .credits {
      text-align: center;
      float: none;
    }
    #footer .credits {
      padding-top: 4px;
    }
  }
`;
