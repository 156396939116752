export const FORM_VALIDATIONS = {
  REQUIRED: {
    required: {
      value: true,
      message: "Please enter value",
    },
  },
  ZIPCODE_ONLY: {
    pattern: {
      value: /^[0-9]*$/,
      message: "Allowed only numbers 0-9",
    },
    maxLength: {
      value: 6,
      message: "Mximum allowed lenght is 6",
    },
    minLength: {
      value: 6,
      message: "Minimum allowed lenght is 6",
    },
  },
};
