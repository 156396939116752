export const ON_BOARD_USER_FORM_DATA = "onBoardUser";

export const userOnBoardFields = {
  firstName: "firstname",
  lastName: "lastname",
  middleName: "middlename",
  email: "email",
  mobile: "mobile",
  dateOfBirth: "date_of_birth",
  gender: "gender",
  country: "country",
  state: "state",
  city: "city",
  zipcode: "zipcode",
  addressLine1: "line_one",
  addressLine2: "line_two",
  joining_date: "joining_date",
  designation: "designation",
  employmentdetails: "employment_details",
  work_permit: "work_permit",
  department: "department",
  reporting_to: "reporting_to",
  role: "role",
};

export const ON_BOARD_USER_DEFAULT_VALUES = {
  firstname: undefined,
  lastname: undefined,
  middlename: undefined,
  email: undefined,
  date_of_birth: undefined,
  gender: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
  line_one: undefined,
  line_two: undefined,
  zipcode: undefined,
  mobile: undefined,
  joining_date: undefined,
  designation: undefined,
  employment_details: undefined,
  work_permit: undefined,
  department: undefined,
  reporting_to: undefined,
  role: undefined,
};

export const TIMESHEET_STATUS: any = {
  SENT_FOR_APPROVAL: "Waiting for response",
  NOT_SENT_FOR_APPROVAL: "Not submitted for Approval",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  undefined: "Open",
};

export const TIMESHEET_STATUS_COLOR: any = {
  SENT_FOR_APPROVAL: "#f5ef42",
  NOT_SENT_FOR_APPROVAL: "#429cf5",
  APPROVED: "#0f6e04",
  REJECTED: "#f54842",
  undefined: "#b7b0b0bf",
};

export const ANIMATION_DEFAULT_TIMEOUT = {
  slide: 500,
};

export const MASKED_AUTH = "auth.docktimer.com";
export const DT_AUTH_DOMAIN = "https://auth.docktimer.com";
export const DT_DOMAIN = "docktimer.com";
export const DT_MASKED_DOMAIN = window.location.host.includes("localhost")
  ? "http://SUB.localhost:3000"
  : "https://www.SUB.docktimer.com";
export const DT_MASKED_DOMAIN_SLUG = window.location.host.includes("localhost")
  ? "http://localhost:3000/SUB/"
  : "https://www.docktimer.com/SUB/";
