import { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Loader } from "../../components/elements/loader/loader-component";
import { getDocs } from "../../rest/api-docs.rest";

const ApiDocs = () => {
  const [openapiSpec, setOpenapiSpec] = useState(null);

  const fetchApiDocs = async () => {
    try {
      const docs = await getDocs();
      console.log("docs:", docs);
      setOpenapiSpec(docs);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchApiDocs();
  }, []);

  return openapiSpec === null ? <Loader /> : <SwaggerUI spec={openapiSpec} />;
};

export default ApiDocs;
