import axios from "axios";

export const getCurrentUser = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/auth/me`
  );

  return response.data;
};

export const getUserDescendantUsers = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/user/descendants`
  );

  return response.data;
};
