import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AxiosError } from "axios";
import { isEmpty, isString } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import VerificationInput from "react-verification-input";
import DocktimerLogo from "../../../../assets/img/docktimer/DockTimer_withoutshadow.svg";
import { LOCAL_STATE } from "../../../../persist-store/app-store";
import {
  errorNotify,
  successNotify
} from "../../../components/notify-components/notify/notify.ui";
import PasswordReset from "../../../components/password-reset/password-reset.component";
import { APP_ROUTE } from "../../../constants/routes.constants";
import {
  requestPasswordChange,
  verifyCode
} from "../../../rest/authentication.rest";
import { verificationCodeDefaultValues } from "../../../utils/form-utils";
import { verificationSchema } from "../../../utils/schema-validations/schema-validations-utils";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../../../utils/session-storage/session-storage.utils";
import { Wrapper } from "./verification-code-page.style";

export const VerificationCodePage = () => {
  const { emailId } = useParams();
  const [isResetToken, setIsResetToken] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const email = useMemo(() => emailId as string, [emailId]); //TODO encrypt decrypt email

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: verificationCodeDefaultValues,
    resolver: yupResolver(verificationSchema),
  });

  const verifyToken = useCallback(
    async (email: string, resetToken: string) => {
      try {
        const response = await verifyCode(email, resetToken);

        if (response) {
          successNotify(t("server.success-email-verified"));
          setSessionStorage(LOCAL_STATE.RESET_TOKEN, response.resetToken);
          setIsResetToken(true);
        }
      } catch (err) {
        errorNotify(((err as AxiosError).response?.data as AxiosError).message);
      }
    },
    [t]
  );

  const onClickResendEmail = async (email: string) => {
    try {
      const response = await requestPasswordChange(email);
      if (response) {
        successNotify(t("server.success-verification-code"));
      }
    } catch (err) {
      return errorNotify(
        ((err as AxiosError).response?.data as AxiosError).message
      );
    }
  };

  const onSubmit = (data: { verificationCode: string | undefined }) => {
    const { verificationCode } = data;

    if (isString(verificationCode)) {
      verifyToken(email as string, verificationCode);
    }
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      errorNotify(errors.verificationCode?.message as string);
    }
  }, [errors]);

  useEffect(() => {
    const handlePopstate = (_event:any) => {
      removeSessionStorage(LOCAL_STATE.RESET_TOKEN);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [window.history]);

  const resetToken = getSessionStorage(LOCAL_STATE.RESET_TOKEN);

  return (
    <Wrapper>
     {
      !resetToken ?  <div className="login-container">
      <section id="login" className="login">
        <div className="loginpage" data-aos="fade-up">
          <div className="formpad">
            <div className="logo">
              <Link to={"/home"}>
                <img alt="" src={DocktimerLogo} />
              </Link>
            </div>
            <div className="back-color">
              <form
                className="login-form text-center"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="text-center">
                  <h3>{t("label.enter-verification-code")}</h3>
                </div>
                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                  {t("message.we-have-sent-a-6-digit-code-to-your-email-id", {
                    emailId: email,
                  })}
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Controller
                    name="verificationCode"
                    control={control}
                    render={({ field }) => {
                      return (
                        <VerificationInput
                          validChars="z0-9"
                          classNames={{
                            container: "container",
                            character: "character",
                            characterInactive: "character--inactive",
                            characterSelected: "character--selected",
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                </Box>
                <button className="submit-btn" type="submit">
                  {t("label.verify")}
                </button>
              </form>

              <Grid container spacing={2} className='justify-content-between'>
                <Grid item xs={4}>
                  <Button
                    variant="text"
                    className="textColor"
                    onClick={() => onClickResendEmail(email as string)}
                  >
                    {t("label.re-send-email-lowercase")}
                  </Button>
                </Grid>
                <Grid item xs={8} className='d-flex justify-content-end'>
                  <Button
                    variant="outlined"
                    className="textColor"
                    onClick={() => navigate(APP_ROUTE.STATIC.FORGOT_PASSWORD)}
                  >
                    {t("label.want-to-change-email-id")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
      {/* End login Section */}
    </div> : <PasswordReset 
                isCurrentPasswordField={false} 
                confirmButtonText="Set New Password" 
                isVerificationPage
                
              />
     }
    </Wrapper>
  );
};
