import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Container,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppStore } from "../../../persist-store/app-store";
import {
  errorNotify,
  successNotify,
} from "../../components/notify-components/notify/notify.ui";
import { APP_ROUTE } from "../../constants/routes.constants";
import { User } from "../../rest/rest-interfaces/user.interface";
import {
  createOrganization,
  getOrganizationDetails,
  updateOrganization,
  updateOrganizationLogoUpdate,
} from "../../rest/subscriber.rest";
import {
  fetchSetCurrentUser,
  replaceSubDomain,
} from "../../utils/common.utils";
import FirstStepBasicDetails from "./steps/FirstStepBasicDetails";
import SecondStepLocationDetails from "./steps/SecondStepLocationDetails";
import ThirdStepDomainSelection from "./steps/ThirdStepDomainSelection";
import { firstStep, secondStep, thirdStep } from "./validation/validation";

const OrganizationPage = (props: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const [orgId, setOrgId] = useState("");
  const [reload, setReload] = useState(false);
  const [localData, setLocalData] = useState<any>(null);
  const { accessToken, setCurrentUser } = useAppStore();
  const [dataLoaded, setDataLoaded] = useState(false);

  const methods = useForm({
    // defaultValues: getDefaultValues(),
    resolver: yupResolver(
      activeStep === 0 ? firstStep : activeStep === 1 ? secondStep : thirdStep
    ),
  });

  useEffect(() => {
    if (props.manage) {
      setDataLoaded(false);
      getOrganizationDetails().then((res) => {
        const data = res.result;
        setOrgId(data._id);
        setLocalData(data);

        // setTimeout(() => {
        methods.setValue("logo", { name: data.logo || "" });
        methods.setValue("name", data.name);
        methods.setValue("organization_email", data.organization_email);
        methods.setValue("about_us", data.about_us);

        methods.setValue("specialities", data.specialities);
        methods.setValue("line_one", data.organization_address.line_one);
        methods.setValue("line_two", data.organization_address.line_two);
        methods.setValue("city", data.organization_address.city);
        methods.setValue("state", data.organization_address.state);
        methods.setValue("country", data.organization_address.country);
        methods.setValue("zipcode", data.organization_address.zipcode);

        methods.setValue("subdomain", data.organization_subdomain);

        setDataLoaded(true);
        // }, 1000);
      });
    } else {
      setDataLoaded(true);
    }
  }, [props.manage, reload]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitForm = async (data: any) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("logo", data.logo);
      formData.append("subdomain", data.subdomain);
      formData.append("about_us", data.about_us);
      formData.append("organization_email", data.organization_email);
      formData.append("specialities", data.specialities);
      formData.append("organization_address[line_one]", data.line_one);
      formData.append("organization_address[line_two]", data.line_two);
      formData.append("organization_address[country]", data.country);
      formData.append("organization_address[state]", data.state);
      formData.append("organization_address[city]", data.city);
      formData.append("organization_address[zipcode]", data.zipcode);

      await createOrganization(formData);
      successNotify("Sucessfully created your organization");
      const redirectingPath = replaceSubDomain(
        data.subdomain,
        APP_ROUTE.DASHBOARD
      );
      window.location.href = redirectingPath;
      setTimeout(() => window.location.reload(), 1500);
    } catch {
      handleBack();
      errorNotify("Error occurred, Try again");
    }
  };

  const updateUser = async () => {
    const resp = await fetchSetCurrentUser();
    setCurrentUser(resp as User);
  };

  const updateForm = async (values: any) => {
    try {
      const payload: any = {
        organization_id: orgId,
        data: {
          specialities: values.specialities,
          about_us: values.about_us,
          organization_address: {
            line_one: values.line_one,
            line_two: values.line_two,
            state: values.state,
            city: values.city,
            country: values.country,
            zipcode: values.zipcode.toString(),
          },
        },
      };

      if (localData.name !== values.name) {
        payload.data.name = values.name;
      }
      await updateOrganization(payload);
      setReload(!reload);

      await updateUser();
      successNotify("Sucessfully updated your organization");
      handleBack();
    } catch {
      handleBack();
      errorNotify("Error occurred, Try again");
    }
  };

  const updateOrgLogo = async (file: any) => {
    try {
      const formData = new FormData();
      formData.append("organization_id", orgId);
      formData.append("remove", "0");
      formData.append("logo", file);

      await updateOrganizationLogoUpdate(formData);
      setReload(!reload);
      await updateUser();
      successNotify("Sucessfully updated your organization logo");
    } catch {
      errorNotify("Error occurred, Try again");
    }
  };

  const handleNext = (values: any) => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    if (newActiveStep > 2) {
      props.manage ? updateForm(values) : submitForm(values);
    }
  };

  const steps = [
    {
      title: "General Details",
      optional: "Provide some general details about your organization",
      componenet: (
        <FirstStepBasicDetails
          manage={props.manage}
          updateOrgLogo={updateOrgLogo}
        />
      ),
    },
    {
      title: "Organization Location Details",
      optional: "Some location details about your organization",
      componenet: <SecondStepLocationDetails />,
    },
    {
      title: "Organization Domain",
      optional: "Recognize your organization from your own domain",
      componenet: <ThirdStepDomainSelection editable={props.manage} />,
    },
  ];

  if (!dataLoaded) {
    return <Box>Please wait...</Box>;
  }
  return (
    <Container>
      <Box mb={5}>
        <Typography fontSize={30} fontWeight="bold">
          Organization
        </Typography>
        <Typography fontSize={15} color="gray">
          {!props.manage
            ? `You have just one step away to onboard your company! to Docktimer`
            : `Manage your organization details`}
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.title}>
            <StepLabel optional={step.optional}>{step.title}</StepLabel>
            <StepContent>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleNext)}>
                  <Box className="box-container">
                    {step.componenet}
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          sx={{ mt: 1, mr: 1 }}
                          type="submit"
                        >
                          {activeStep === 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  </Box>
                </form>
              </FormProvider>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default OrganizationPage;
