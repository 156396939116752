import * as yup from "yup";
import { userOnBoardFields } from "../constants/constants.constant";

export const signUpFromSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  mobile: yup.string().required(),
  email: yup.string().required(),
  password: yup.string().required().min(8, "Password is too short").max(16),
  termsAndConditions: yup.string().required(),
});

export const loginFromSchema = yup.object({
  password: yup.string().required(),
  email: yup.string().email().required(),
});

export const forgotFromSchema = yup.object({
  email: yup.string().email().required(),
});

export const verificationSchema = yup.object({
  verificationCode: yup.string().required("Verification code is required"),
});

export const basicInfoFormSchema = yup.object({
  [userOnBoardFields.firstName]: yup
    .string()
    .required("first name is required")
    .min(3)
    .max(20),
  [userOnBoardFields.lastName]: yup
    .string()
    .required("last name is required")
    .min(3)
    .max(20),
  [userOnBoardFields.email]: yup
    .string()
    .email("Field should contain a valid e-mail")
    .max(255)
    .required("email is required"),
  [userOnBoardFields.dateOfBirth]: yup
    .date()
    .required("date of birth is required"),
  [userOnBoardFields.gender]: yup.string().required("gender is required"),
  [userOnBoardFields.mobile]: yup
    .string()
    .required("mobile number is required")
    .min(10)
    .max(10),
});

export const locationInfoFormSchema = yup.object({
  [userOnBoardFields.addressLine1]: yup
    .string()
    .required("address line 1 is required")
    .min(3, "address should be min 3 characters")
    .max(150, "address should not more than be 150 characters"),
  [userOnBoardFields.city]: yup.string().required(),
  [userOnBoardFields.country]: yup.string().required("country is required"),
  [userOnBoardFields.zipcode]: yup.string().required("zipcode is required"),
  [userOnBoardFields.state]: yup.string().required("state is required"),
});

export const occupationalDetailsSchema = yup.object({
  [userOnBoardFields.work_permit]: yup
    .string()
    .required("work permit is required"),
  [userOnBoardFields.designation]: yup
    .string()
    .required("designation is required"),
  [userOnBoardFields.employmentdetails]: yup
    .string()
    .required("employmentdetails is required"),
  [userOnBoardFields.department]: yup
    .string()
    .required("department is required"),
});
