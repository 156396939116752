import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";

interface WrapperProps {
  image: string;
}
export const Wrapper = styled.div<WrapperProps>`
  #hero {
    width: 100%;
    height: 400px;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat, no-repeat, repeat;
    overflow: hidden;
    background-position: bottom;
    margin-bottom: 100px;
    .banner-section-title {
      margin-top: 10rem;

      h2 {
        color: ${styleConstants.WHITE_COLOR};
        font-size: 2rem;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: ${styleConstants.SECONDARY_COLOR};
          bottom: -7px;
          left: calc(50% - 10px);
        }

        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 120px;
          height: 1px;
          background: #ddd;
          bottom: 1px;
          left: calc(50% - 60px);
        }
      }

    
    }
  }

  .about-us-contain {
    p {
    letter-spacing: 0;
    color: ${styleConstants.WHITE_COLOR};
    line-height: 1.7;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 4rem 17rem 0;
    text-align:justify;
  }
}
  @media (min-width: 320px) and (max-width: 767px) {
    #hero {
      .banner-section-title {
        margin-top: 6rem;

        h2 {
          font-size: 52px;
        }
        p {
          font-size: 1.2rem;
          margin: 0;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    #hero {
      .banner-section-title {
        p {
          margin: 3rem 1rem 0;
        }
      }
    }
  }
`;
