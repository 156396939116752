import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";
interface WrapperProps {
  image: string;
}

export const Wrapper = styled.div<WrapperProps>`
  #hero {
    width: 100%;
    height: 400px;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat, no-repeat, repeat;
    overflow: hidden;
    background-position: bottom;
    margin-bottom: 100px;

    .banner-section-title {
      margin-top: 10rem;
      p {
        letter-spacing: 0;
        color: ${styleConstants.WHITE_COLOR};
        line-height: 1.7;
        font-weight: 600;
        font-size: 1.5rem;
        margin: 6rem 17rem 0;
      }
    }
  }

  /*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
  #contact {
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .contact .info {
    border-top: 3px solid ${styleConstants.PRIMARY_COLOR};
    border-bottom: 3px solid ${styleConstants.PRIMARY_COLOR};
    padding: 30px;
    background: ${styleConstants.WHITE_COLOR};
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  }

  .contact .info i {
    font-size: 20px;
    color: ${styleConstants.PRIMARY_COLOR};
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${styleConstants.PRIMARY_COLOR};
  }

  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
  }

  .contact .info .email p {
    padding-top: 5px;
  }

  .contact .info .social-links {
    padding-left: 60px;
  }

  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: ${styleConstants.WHITE_COLOR};
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }

  .contact .info .social-links a:hover {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

  .contact .php-email-form {
    width: 100%;
    border-top: 3px solid ${styleConstants.PRIMARY_COLOR};
    border-bottom: 3px solid ${styleConstants.PRIMARY_COLOR};
    padding: 30px;
    background: ${styleConstants.WHITE_COLOR};
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    label {
      color: ${styleConstants.PRIMARY_COLOR};
      font-weight: 600;
    }
  }

  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }

  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }

  .contact .php-email-form .error-message {
    display: none;
    color: ${styleConstants.WHITE_COLOR};
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }

  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }

  .contact .php-email-form .sent-message {
    display: none;
    color: ${styleConstants.WHITE_COLOR};
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }

  .contact .php-email-form .loading {
    display: none;
    background: ${styleConstants.WHITE_COLOR};
    text-align: center;
    padding: 15px;
  }

  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }

  .contact .php-email-form .form-group {
    margin-bottom: 20px;
    .form-control {
      border-radius: 25px;
    }
  }

  .contact .php-email-form label {
    padding-bottom: 8px;
  }

  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }

  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: ${styleConstants.PRIMARY_COLOR};
  }

  .contact .php-email-form input {
    height: 44px;
  }

  .contact .php-email-form textarea {
    padding: 10px 12px;
  }

  .contact .php-email-form button[type="submit"] {
    background: ${styleConstants.PRIMARY_COLOR};
    border: 0;
    padding: 12px 34px;
    color: ${styleConstants.WHITE_COLOR};
    transition: 0.4s;
    border-radius: 50px;
  }

  .contact .php-email-form button[type="submit"]:hover {
    background: ${styleConstants.WHITE_COLOR};
    border: 2px solid ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.PRIMARY_COLOR};
    font-weight: 600;
  }

  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    #hero {
      .banner-section-title {
        h2 {
          font-size: 52px;
        }
        p {
          font-size: 1.2rem;
          margin: 0;
        }
      }
    }
  }
`;
