import { AxiosError } from "axios";
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  getCurrentUser,
  getUserDescendantUsers,
} from "../get-current-user.rest";
import { User } from "../rest-interfaces/user.interface";

export enum Status {
  ERROR = "ERROR",
  FETCHING = "FETCHING",
  INITIAL = "INITIAL",
  DONE = "DONE",
}

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [errorMsg, setMessage] = useState("");
  const [status, setStatus] = useState(Status.INITIAL);

  const fetchCurrentUser = useCallback(async () => {
    setStatus(Status.FETCHING);
    try {
      const currentUserData = await getCurrentUser();
      setCurrentUser(currentUserData);
      setMessage("");
      setStatus(Status.DONE);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data;
      setCurrentUser(null);
      setMessage(errorMessage as string);
      setStatus(Status.ERROR);
    }
  }, []);

  return {
    fetchCurrentUser,
    currentUser,
    errorMsg,
    status,
  };
};

export const useDescendantUsers = () => {
  const [descendants, setDescendants] = useState<User | null>(null);
  const [errorMsg, setMessage] = useState("");
  const [status, setStatus] = useState(Status.INITIAL);

  const getDescendantUsers = useCallback(async () => {
    try {
      const desc = await getUserDescendantUsers();
      setDescendants(desc);
      setMessage("");
      setStatus(Status.DONE);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data;
      setDescendants(null);
      setMessage(errorMessage as string);
      setStatus(Status.ERROR);
    }
  }, []);

  return {
    getDescendantUsers,
    descendants,
    errorMsg,
    status,
  };
};

const useDidMountEffect = (
  callback: EffectCallback,
  dependencies: DependencyList
) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      callback();
    } else {
      isMounted.current = true;
    }
  }, dependencies);
};

export default useDidMountEffect;
