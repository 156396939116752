// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-conditions {
  margin-left: 4px;
  display: flex;
  margin-bottom: 1rem;
}

.agree-terms {
  margin-left: 4px;
  font-weight: 500;
}

.input {
  width: 100%;
  border-radius: 24px;
}

.input .p-password-input {
  border-radius: 24px;
}

.signup .signup-form input,
.fdjohn .signup .signup-form textarea {
  width: 100%;
  border-radius: 24px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/static/signup-page/signup-page.style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;EACX,8BAA8B;AAChC","sourcesContent":[".terms-and-conditions {\n  margin-left: 4px;\n  display: flex;\n  margin-bottom: 1rem;\n}\n\n.agree-terms {\n  margin-left: 4px;\n  font-weight: 500;\n}\n\n.input {\n  width: 100%;\n  border-radius: 24px;\n}\n\n.input .p-password-input {\n  border-radius: 24px;\n}\n\n.signup .signup-form input,\n.fdjohn .signup .signup-form textarea {\n  width: 100%;\n  border-radius: 24px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
