/*! Generated by Font Squirrel (https://www.fontsquirrel.com)*/

export enum styleConstants {
  BLUE_COLOR = "#3232bb",
  SECONDARY_COLOR = "#ff9f42;",
  WHITE_COLOR = "#fff",
  BLACK_COLOR = "#00031f",
  TERTIARY_COLOR = "#EFF2FF",
  BOX_SHADOW_INSET3 = "5px 5px 10px #FAFBFF, 5px 5px 10px #A6ABBD",
  INNER_SHADOW_GRADIENT = "linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #EFF2FF",
  INNER_BOX_SHADOW = " inset -5px -5px 10px #FAFBFF, inset 5px 5px 10px #A6ABBD",
  BACK_BLEND_MODE = "soft-light, normal;",
  BORDER = "1px solid rgba(255, 255, 255, 0.4)",
  BORDER_RADIUS_10 = "10px",
  LIGHT_GREY_TEXT_COLOR = "#414b5a",
  BLUE_GRADIENT = "linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #3232bb",
  BLUE_GRADIENT1 = "linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EFF2FF",
  BOX_SHADOW = "0px 3px 5px rgba(0, 0, 0, 0.15)",
  BOX_SHADOW1 = "-5px -5px 10px #FAFBFF, 5px 5px 10px #A6ABBD",
  INNER_SHADOW_GRADIENT_ORANGE = "linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #FF9F42",
  BORDER_COLOR = "#ced4da",
  BOX_SHADOW_BORDER_EFFECTS = "-5px -5px 10px #FAFBFF, 5px 5px 10px #A6ABBD;",
  DEFAULT_FONT = "interregular, system-ui",
  DEFAULT_FONT_BOLD = "interbold, system-ui",
  PRIMARY_COLOR = "#3232bb",
  DARK_GREY_COLOR = "#00031f;",
  LIGHT_GREY_COLOR = "#00031f",
  PRIMARY_COLOR_LIGHT = "#3232bb7d",
  SUCCESS_COLOR = "#2e7d32",
  ERROR_COLOR = ""
}
