import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";
interface WrapperProps {
  image: string;
}

export const Wrapper = styled.div<WrapperProps>`
  #hero {
    width: 100%;
    height: 400px;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat, no-repeat, repeat;
    overflow: hidden;
    background-position: bottom;
    margin-bottom: 100px;

    .banner-section-title {
      margin-top: 10rem;
      p {
        letter-spacing: 0;
        color: ${styleConstants.WHITE_COLOR};
        line-height: 1.7;
        font-weight: 600;
        font-size: 1.5rem;
        margin: 6rem 17rem 0;
      }
    }
  }

  /*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
  #Pricing {
    padding-bottom: 100px;
  }
  .pricing-section {
    box-shadow: 0 0 0.5rem #5053c44d;
    background: #fff;
    border-radius: 16px;
    --count: 1fr 1fr 1fr;
    --areas: "header_0 header_1 header_2" "desc_0 desc_1 desc_2";
    z-index: 50;
    position: relative;
    grid-template-columns: var(--count);
    grid-template-areas: var(--areas);
    padding: 0;
    margin-top: 2rem;
    display: grid;
  }

  .pricing__header {
    --area: header_0;
    grid-area: var(--area);
    border: none;
    background: linear-gradient(#0000, #2626260d);
    border-bottom: #0000;
    border-radius: 1rem 1rem 0 0;
    padding: 2rem 1.5rem;
    z-index: 10;
    text-align: center;
    border: 2px solid #5053c41a;
    position: relative;
    h4 {
      font-size: 1.953rem;
      margin-top: 0;
      margin-bottom: 1.38rem;
      font-weight: 700;
      line-height: 1.3;
    }
  }
  .mp-separator {
    background-color: currentColor;
    border: 0;
    opacity: 1;
    width: 2.5rem;
    height: 5px;
    color: ${styleConstants.PRIMARY_COLOR};
    margin: 1rem auto;
    border-radius: 5px;
  }
  .pricing__select {
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
  }
  .input--radio {
    --size: 3.5rem;
    --spacer: 2.25px;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    border: 3px solid ${styleConstants.PRIMARY_COLOR_LIGHT};
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input--radio:before {
    width: calc(var(--size) - var(--spacer) * 4);
    height: calc(var(--size) - var(--spacer) * 4);
    background: ${styleConstants.PRIMARY_COLOR_LIGHT};
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
    content: "\f00c";
    font: normal normal normal 20px/1 FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${styleConstants.WHITE_COLOR};
  }

  .input--radio:hover:after {
    border-color: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }
  .input--radio:hover:before {
    transform: translate(-50%, -50%) scale(0.9);
  }

  .input--radio:hover:before {
    background: ${styleConstants.PRIMARY_COLOR};
  }
  .input--radio:after {
    content: "\f00c";
    font: normal normal normal 20px/1 FontAwesome;
    animation: 0.25s cubic-bezier(0.82, -0.56, 0.28, 1.84) forwards checked;
  }

  .pricing__desc {
    --area: desc_0;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    padding: 2rem;
    margin-bottom: 0;
    ul {
      text-align: left;
      margin: 1rem 0;
      li {
        position: relative;
        padding-left: 1.8rem;
        margin-bottom: 0.5rem;
        list-style: none;
        ::before {
          content: "\f00c";
          font: normal normal normal 20px/1 FontAwesome;
          display: inline-block;
          position: absolute;
          left: -0.5rem;
          top: 5px;
          transition: all 0.2s;
          color: #22cd22;
        }
      }
    }
  }

  .pricing__header--high {
    --area: header_1;
    margin-top: -1rem;
    padding-top: 3rem;
    box-shadow: 0 0 0.5rem ${styleConstants.PRIMARY_COLOR_LIGHT};
    grid-area: var(--area);
    border: none;
    color: ${styleConstants.WHITE_COLOR};
    background: ${styleConstants.PRIMARY_COLOR};
    h4 {
      color: ${styleConstants.WHITE_COLOR};
    }
  }
  .pricing__highlight {
    margin: 0;
    position: absolute;
    top: -0.5rem;
    left: 0;
    right: 0;
    transform: translateY(0);
    background: ${styleConstants.SECONDARY_COLOR};
    border-radius: 1rem 1rem 0 0;
    padding: 0.25rem 0;
    font-size: 1rem;
    color: ${styleConstants.WHITE_COLOR};
  }
  .pricing__header--high hr {
    color: ${styleConstants.SECONDARY_COLOR};
  }

  .pricing__price--package {
    grid-column-gap: 1rem;
    max-width: 300px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "label-top label-top"
      "edition package";
    margin: 0 auto;
    display: grid;
    position: relative;
    padding-bottom: 1rem;
    line-height: 1;
  }

  .pricing__price--package:before {
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "+";
    color: #00c5b4;
    grid-area: plus;
    font-size: 1.5rem;
    font-weight: 400;
  }
  .pricing__price--package span:first-child {
    font-weight: 500;
    grid-area: label-top;
    font-size: 0.75rem;
    line-height: 0.9rem;
    opacity: 0.8;
  }

  .pro-input--radio {
    --size: 3.5rem;
    --spacer: 2.25px;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    border: 3px solid ${styleConstants.WHITE_COLOR};
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pro-input--radio:before {
    width: calc(var(--size) - var(--spacer) * 4);
    height: calc(var(--size) - var(--spacer) * 4);
    background: #ff9f4296;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    transition: all 0.2s ease-in-out;
    content: "\f00c";
    font: normal normal normal 20px/1 FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${styleConstants.WHITE_COLOR};
  }
  .pro-input--radio:hover:before {
    background: ${styleConstants.SECONDARY_COLOR};
  }

  .pro-input--radio:after {
    content: "\f00c";
    font: normal normal normal 20px/1 FontAwesome;
    animation: 0.25s cubic-bezier(0.82, -0.56, 0.28, 1.84) forwards checked;
  }

  .pricing__price--package {
    .pricing {
      grid-area: edition;
      h5 {
        margin: 0.7rem 0 0.15rem;
        line-height: 1;
        font-size: 1.25rem;
        color: ${styleConstants.WHITE_COLOR};
      }
      span {
        font-size: 0.75rem;
        line-height: 0.9rem;
        opacity: 0.8;
      }
    }
    .package {
      flex-direction: column;
      justify-content: center;
      display: flex;
      grid-area: package;
      .package__price {
        margin: 0.7rem 0 0.15rem;
        line-height: 1;
        font-size: 1.25rem;
        color: ${styleConstants.WHITE_COLOR};
      }
      .package__payment {
        opacity: 0.8;
        font-size: 0.75rem;
        line-height: 0.9rem;
      }
    }
  }

  .pro_pricing__select {
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pricing__desc--high {
    --area: desc_1;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    margin-bottom: -1.5rem;
    padding-bottom: 2rem;
    box-shadow: 0 0 0.5rem #5053c44d;
    grid-area: var(--area);
    border: none;
    background: #fff;
    border-radius: 0 0 1rem 1rem;
    padding: 2rem;
    ul {
      text-align: left;
      margin: 1rem 0;
      li {
        position: relative;
        padding-left: 1.8rem;
        margin-bottom: 0.5rem;
        list-style: none;
        ::before {
          content: "\f00c";
          font: normal normal normal 20px/1 FontAwesome;
          display: inline-block;
          position: absolute;
          left: -0.5rem;
          top: 5px;
          transition: all 0.2s;
        }
      }
    }
    strong {
      display: flex;
    }
  }
  .pricing__desc--premium {
    --area: desc_2;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    margin-bottom: -1.5rem;
    padding-bottom: 2rem;
    grid-area: var(--area);
    border: none;
    padding: 2rem;
    ul {
      text-align: left;
      margin: 1rem 0;
      li {
        position: relative;
        padding-left: 1.8rem;
        margin-bottom: 0.5rem;
        list-style: none;
        ::before {
          content: "\f00c";
          font: normal normal normal 20px/1 FontAwesome;
          display: inline-block;
          position: absolute;
          left: -0.5rem;
          top: 5px;
          transition: all 0.2s;
        }
      }
    }
    strong {
      display: flex;
    }
  }

  .pricing_premium {
    --area: header_2;
  }

  .buy-button {
    background-color: ${styleConstants.PRIMARY_COLOR};
    border: 0;
    border-radius: 16px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 25px;
    text-align: center;
    transition: all 0.3s;
  }

  .buy-button:is(:hover, :focus) {
    outline-offset: 3px;
    transform: scale(1.05);
  }

  @media (min-width: 320px) and (max-width: 1024px) {
    .pricing-section {
      box-shadow: none;
      background: none;
      display: block;
    }
    .tabs-pricing {
      border: 1px solid #3232bb;
      border-radius: 18px;
      margin: 50px 0;
    }
  }
`;
