export const SLUG_PLACEHOLDER = {
  EMAIL_ID: ":emailId",
  SUB_DOMAIN: ":subDomain",
  VERIFY_TOKEN: ":verifyToken",
};
export const SUB_DOMAIN = "subDomain";

export const APP_ROUTE = {
  STATIC: {
    HOMEPAGE: "/home",
    CONTACT_PAGE: "/contact-us",
    ABOUT_PAGE: "/about-us",
    TIME_MANAGE_PAGE: "/time-management",
    LEAVE_MANAGE_PAGE: "/leave-management",
    HR_MANAGE_PAGE: "/hr-management",
    EXPENSE_MANAGE_PAGE: "/expense-management",
    REPORT_MANAGE_PAGE: "/report-management",
    LOGIN: "/login",
    SIGN_UP: "/signup",
    PRICING: "/pricing",
    FORGOT_PASSWORD: `/forgot-password`,
    VERIFIED_PAGE: "/verified",
    VERIFY_CONFIRMATION_CODE: `/verify-confirmation-code/${SLUG_PLACEHOLDER.EMAIL_ID}`,
    VERIFY_EMAIL: `/auth/verifyEmail/${SLUG_PLACEHOLDER.VERIFY_TOKEN}`,
  },

  DASHBOARD: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/dashboard`,
  TIME_SHEET: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/time-sheets`,
  ADDTIMESHEET: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/addtimesheets/:id`,
  TASK: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/task`,
  PROJECT: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/project`,
  REPORTS: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/reports`,
  SETTINGS: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/settings`,
  IMPORTEXPORT: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/import&export`,
  NOTIFICATION: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/notifications`,
  MANAGE: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/manage`,
  MANAGE_USERS: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/manage-users`,
  HR: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/hr`,
  MY_PROFILE: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/my-profile`,

  ON_BOARD_EMPLOYEE: `/onboard-employee`,
  EDIT_EMPLOYEE: "/onboard-employee/:id",
  API_DOCS: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/api-docs`,
  CLIENTS: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/clients`,
  LOGOUT: "/logout",

  ORG: `${SLUG_PLACEHOLDER.SUB_DOMAIN}/manage-organization`,
  ORG_CREATE: "/create-organization",
};

export const backendApiPaths = {
  register: "/users/register",
  registerSubscriberAdmin: "/users/subscriber-admin",
  login: "/users/login",
  addProject: "/users/subscriber-admin/projects/add-project",
  projects: "/users/subscriber-admin/projects",
};

export const getStaticRoutes = () => Object.values(APP_ROUTE.STATIC);
