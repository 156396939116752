import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import DocktimerLogo from "../../../../assets/img/docktimer/DockTimer_Logo.svg";
import login_imgage from "../../../../assets/img/docktimer/Login-page-img.svg";
import { Loader } from "../../../components/elements/loader/loader-component";
import {
  errorNotify,
  successNotify
} from "../../../components/notify-components/notify/notify.ui";
import { ErrorTextfield } from "../../../components/notify-components/warn-textfield/warn-textfield";
import { APP_ROUTE } from "../../../constants/routes.constants";
import { addSubscriber } from "../../../rest/authentication.rest";
import { SignUpForm, signUpDefaultValues } from "../../../utils/form-utils";
import { signUpFromSchema } from "../../../utils/schema-validations/schema-validations-utils";
import "./signup-page.style.css";
import { Wrapper } from "./signup-style";

export const SignUpPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);

  const backToLogin = () => {
    navigate(APP_ROUTE.STATIC.LOGIN);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: signUpDefaultValues,
    resolver: yupResolver(signUpFromSchema),
  });

  const onSubmit = async (
    data: SignUpForm,
    isTermsAndConditionsAccepted: boolean
  ) => {
    const payload = {
      firstname: data.firstName,
      middlename: !isEmpty(data.middleName) ? data.middleName : " ",
      lastname: data.lastName,
      mobile: data.mobile,
      email: data.email,
      password: data.password,
    };

    if (isTermsAndConditionsAccepted) {
      try {
        setIsLoading(true);
        await addSubscriber(payload);

        successNotify(t("message.thank-you-for-registering-with-us"));
        backToLogin();
        reset(signUpDefaultValues);
      } catch (error) {
        errorNotify(
          ((error as AxiosError).response?.data as AxiosError).message
        );
      }
    }
    setIsLoading(false);
    
  };

  const RequiredSpan = () => (
    <span style={{ color: "orange", marginLeft: "0.2rem" }}>*</span>
  );

  const header = <h6>Pick a password</h6>;
  const footer = (
    <Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </Fragment>
  );
  return (
    <Wrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* ///signup form */}
          <div className="signup-container">

            <section id="signup" className="signup">
              <div className="container signuppage" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0">
                    <img className="side-img" src={login_imgage} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-12 formpad">
                    <div className="back-color">
                      <div className="text-center">
                        <div className="Logo-Name">
                          <Link to={"/home"}>
                            <img alt="" src={DocktimerLogo} />
                          </Link>
                          <h3>  <span>{t("label.dock-timer")}</span> </h3>
                        </div>
                        <h3>{t("label.sign-up-your-account")}</h3>
                      </div>

                      {/* Sign up form */}
                      <form
                        className="signup-form"
                        onSubmit={handleSubmit((e) =>
                          onSubmit(
                            e as unknown as SignUpForm,
                            isTermsAndConditionsAccepted
                          )
                        )}
                      >
                        <div className="form-group">
                          <label htmlFor="firstName">
                            {t("label.first-name")}
                          </label>
                          <RequiredSpan />
                          <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => {
                              return (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={
                                    t("label.enter-first-name") as string
                                  }
                                  {...field}
                                />
                              );
                            }}
                          />
                          {errors.firstName?.message && (
                            <ErrorTextfield>
                              {t("message.field-is-required", {
                                field: t("label.first-name"),
                              })}
                            </ErrorTextfield>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="middleName">
                            {t("label.middle-name")}
                          </label>
                          <Controller
                            name="middleName"
                            control={control}
                            render={({ field }) => {
                              return (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter middle name"
                                  {...field}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastName">
                            {t("label.last-name")}
                          </label>
                          <RequiredSpan />
                          <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => {
                              return (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter last name"
                                  {...field}
                                />
                              );
                            }}
                          />
                          {errors.lastName?.message && (
                            <ErrorTextfield>
                              {t("message.field-is-required", {
                                field: t("label.last-name"),
                              })}
                            </ErrorTextfield>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">{t("label.email")}</label>
                          <RequiredSpan />
                          <Controller
                            name={"email"}
                            control={control}
                            render={({ field }) => {
                              return (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("label.enter-email") as string}
                                  {...field}
                                />
                              );
                            }}
                          />
                          {errors.email?.message && (
                            <ErrorTextfield>
                              {" "}
                              {t("message.field-is-required", {
                                field: t("label.email"),
                              })}
                            </ErrorTextfield>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">{t("label.mobile")}</label>
                          <RequiredSpan />
                          <Controller
                            name="mobile"
                            control={control}
                            render={({ field }) => {
                              return (
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter mobile number"
                                  {...field}
                                />
                              );
                            }}
                          />
                          {errors.mobile?.message && (
                            <ErrorTextfield>
                              {t("message.field-is-required", {
                                field: t("label.mobile"),
                              })}
                            </ErrorTextfield>
                          )}
                        </div>
                        <div className="form-group">
                          <div>
                            <label htmlFor="name">{t("label.password")}</label>
                            <RequiredSpan />
                          </div>
                          <Controller
                            name={"password"}
                            control={control}
                            render={({ field }) => {
                              return (
                                <Password
                                  className="input"
                                  {...field}
                                  header={header}
                                  footer={footer}
                                  placeholder={
                                    t("label.please-enter-password") as string
                                  }
                                />
                              );
                            }}
                          />
                          {errors.password?.message && (
                            <ErrorTextfield>
                              {t("message.field-is-required", {
                                field: t("label.password"),
                              })}
                            </ErrorTextfield>
                          )}
                        </div>
                        <div className="form-group">
                          <div>
                            <label htmlFor="name">
                              {t("label.confirm-password")}
                            </label>
                            <RequiredSpan />
                          </div>
                          <Controller
                            name={"confirm_password"}
                            control={control}
                            render={({ field }) => {
                              return (
                                <Password
                                  style={{
                                    display: "flex",
                                    borderRadius: "24px",
                                  }}
                                  {...field}
                                  placeholder={
                                    t(
                                      "label.re-confirm-entered-password"
                                    ) as string
                                  }
                                  toggleMask
                                  content={
                                    <div>
                                      {!getValues("password") ? (
                                        <div>
                                          {t("label.please-enter-password")}
                                        </div>
                                      ) : null}
                                      {getValues("password") &&
                                        getValues("password") ===
                                        getValues("confirm_password") ? (
                                        <div>{t("label.password-matched")}</div>
                                      ) : (
                                        getValues("password") && (
                                          <div>
                                            {t("label.password-is-not-matched")}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  }
                                />
                              );
                            }}
                          />
                          {errors.password?.message && (
                            <ErrorTextfield>
                              {t("message.confirm-your-password")}
                            </ErrorTextfield>
                          )}
                        </div>

                        <div className="terms-and-conditions">
                          <Checkbox
                            checked={isTermsAndConditionsAccepted}
                            onChange={(e) =>
                              setIsTermsAndConditionsAccepted(e.checked)
                            }
                          />
                          <span className="agree-terms">
                            {t(
                              "message.please-agree-to-our-terms-condition-plural"
                            )}
                          </span>
                        </div>
                        <div className="text-center">
                          <button type="submit" className="submit-btn">
                            {t("label.sign-up")}
                          </button>
                        </div>
                        <div className="signuplink text-center mt-3">
                          <p>
                            {t("label.already-have-an-account")}?{" "}
                            <span onClick={backToLogin}>
                              {t("label.login")}
                            </span>{" "}
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* End Sign up Section */}
        </>
      )}
    </Wrapper>
  );
};
