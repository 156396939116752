import BannerImage from "../../../../assets/img/banner-images/background_2.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./time-management-page-styles";

import circleimg1 from "../../../../assets/img/servicebanner/service-inside-img/2-TM-DigitalTimeSheets.svg";
import circleimg2 from "../../../../assets/img/servicebanner/service-inside-img/3-TM-Projectregulation.svg";
import circleimg3 from "../../../../assets/img/servicebanner/service-inside-img/4-TM-TeamMonitoring.svg";

export const TimeManagement = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>Time Management</h2>
                <p>
                  Time equals money. Losing time isn't affordable because it's
                  essentially the same as losing money. Adequate time management
                  and tracking will result in precious productivity, which will
                  motivate enterprises to reach new heights.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="animation-area">
                        <ul className="box-area">
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                        </ul>
                    </div> */}
        </section>
        {/* End Hero */}
        <div className="main">
          <div className="time-section">
            <div className="container">
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg1} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Digital Timesheet</h2>
                    </div>
                  </div>
                  <div className="col-lg-6 right-content">
                    <div className="section-content">
                      <p>
                        With digital timesheets, you can monitor, track, and
                        authorize leaves while removing the need for clocks,
                        note-taking, and human inputs with just a few clicks
                        from anywhere and at any time using your devices. You
                        can easily keep a detailed daily record of your
                        employees’ time on papers, meetings, emails, websites,
                        and video chats.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg2} alt="" />
                  </div>
                </div>
                <div className="row responsive">
                  <div className="col-lg-6">
                    <div className="section-content-left">
                      <p>
                        The reports and data docktimer gives are easy to read
                        and interpret, which helps to control your project and
                        follow them in a step-by-step procedure, allowing you to
                        do a quick scan to enhance productivity and enjoy better
                        results.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 maim-content-head-right">
                    <div className="content-head">
                      <h2>Project Regulation</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <div className="left-curve-img" />
                <div className="right-curve-img" />
                <div className="img-center">
                  <div className="img-circle-top">
                    <img src={circleimg3} alt="" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 maim-content-head-top">
                    <div className="content-head">
                      <h2>Team Monitoring</h2>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-content">
                      <p>
                        Docktimers' simple design benefits you by simplifying
                        your everyday tasks and will act as a helpful gear to
                        keep an eye on the ongoing project, ultimately
                        amplifying the results in the appropriate time frame.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </StaticSkeleton>
  );
};
