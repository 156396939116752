import styled from "styled-components";
import { styleConstants } from "../../../../styles/StyleConstants";
interface WrapperProps {
  image: string;
}

export const Wrapper = styled.div<WrapperProps>`
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/

  .staticNavbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: transparent;
  }

  .staticNavbar.active {
    background-color: ${styleConstants.PRIMARY_COLOR};
  }

  .bi-box-arrow-in-right::before {
    font-size: 24px;
    margin-right: 10px;
  }

  .top-header {
    padding: 6px 0;
  }

  .navbar {
    width: 100%;
    .navbar-brand{
        img{
           width: 45px;
        }
        span{
    margin-top: 12px;
    font-size: 22px;
    margin-left: 10px;
        }
    }
  }

  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    // content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url(${(props) => props.image});
  }

  .navbar-expand-lg .navbar-collapse {
    justify-content: end;
  }

  .navbar {
    padding: 0;
  }

  .navbar .getstartbtn {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    color: ${styleConstants.WHITE_COLOR};
    border: 2px solid ${styleConstants.WHITE_COLOR};
  }

  .navbar .getstartbtn:hover {
    background: ${styleConstants.WHITE_COLOR};;
    color: #000;
    border: 2px solid ${styleConstants.SECONDARY_COLOR};
  }

  .navbar .btn-get-started {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    color: ${styleConstants.WHITE_COLOR};
    border: 2px solid ${styleConstants.WHITE_COLOR};
  }

  .navbar .btn-get-started:hover {
    background: ${styleConstants.WHITE_COLOR};
    color: #000;
    border: 2px solid ${styleConstants.SECONDARY_COLOR};
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar-light .navbar-nav .nav-link {
    color:${styleConstants.WHITE_COLOR};
}

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-right: 16px;
    font-size: 1.1rem;
    font-weight: 600;
    color: ${styleConstants.WHITE_COLOR};
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar-nav a:hover,
  .navbar-nav .active,
  .navbar-nav .active:focus,
  .navbar-nav li:hover > a {
    color: ${styleConstants.SECONDARY_COLOR};
  }

  .navbar .active,
  .navbar .active:focus {
    border-radius: 13px;
    background: ${styleConstants.WHITE_COLOR};fff;
    color: ${styleConstants.PRIMARY_COLOR};
  }

  .navbar .getstarted,
  .navbar .getstarted:focus {
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 50px;
    color: ${styleConstants.WHITE_COLOR};
    font-size: 14px;
    border: 2px solid ${styleConstants.PRIMARY_COLOR};
    font-weight: 600;
  }

  .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    color: ${styleConstants.WHITE_COLOR};
    background: ${styleConstants.PRIMARY_COLOR};
  }

  .header-scrolled .navbar .active {
    background: ${styleConstants.PRIMARY_COLOR};
    color: ${styleConstants.WHITE_COLOR};
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: ${styleConstants.WHITE_COLOR};
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    color: ${styleConstants.PRIMARY_COLOR};
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover > a {
    color: ${styleConstants.SECONDARY_COLOR};
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  .responsive-view{
    display: none;
  }
///responsive-view//

.responsive-view{

    .navigation-menu.expanded{
        position: fixed;
        overflow: hidden;
        top: 80px;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(40, 58, 90, 0.9);
        transition: 0.3s;
        z-index: 999;
      }

    .services .expandedList{
            display: none;
    }

    .services.servicesList .expandedList {
        display: block;
      }

    .navigation {
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0.5rem 1rem;
        background-color: ${styleConstants.PRIMARY_COLOR};
        color: black;
        box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
      }
      .brand-name {
        height: 60px;
        position: relative;
        img{
            height: 100%;
        }
        span{
            position: absolute;
            top: 19px;
            left: 65px;
            font-size: 22px;
            color:${styleConstants.WHITE_COLOR};
            font-weight: 600;
        }
    }
      .navigation-menu {
        margin-left: auto;
      }

      .navigation-menu .listUl {
        display: flex;
        padding: 0;
      }
      .navigation-menu li {
        // removes default disc bullet for li tags and applies margin to left & right side
        list-style-type: none;
        margin-bottom: 10px;
      }
      .navigation-menu li a {
        // increases the surface area of the anchor tag to span more than just the anchor text
        text-decoration: none;
        display: block;
        width: 100%;
      }

      .hamburger {
        // removes default border on button element
        border: 0;
        height: 40px;
        width: 40px;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: ${styleConstants.PRIMARY_COLOR};
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
      // positions the icon to the right and center aligns it vertically
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        display: none;
        svg{
        color: ${styleConstants.WHITE_COLOR};
        width: 1.3em;
        height: 1.3em;
        }
      }
     
}
  @media (min-width: 320px) and (max-width: 1024px){
    .top-header{
        display: none;
    }
    .responsive-view{
        display: block;
        .hamburger {
            display: block;
          }
          .navigation-menu .listUl {
              display: none;
            }
      .navigation-menu .listUl {
        // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)
        position: absolute;
        top: 80px;
        left: 0;
        flex-direction: column;
        background-color: ${styleConstants.WHITE_COLOR};;
        border-top: 1px solid black;
        box-shadow: 0 15px 40px 10px #0000001a;
        padding: 10px 10px 0 10px;
        position: absolute;
        top: 55px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        border-radius: 10px;
        background-color: #fff;
        overflow-y: auto;
        transition: 0.3s;
      }
      .navigation-menu li a {
        color: ${styleConstants.PRIMARY_COLOR};
        // increases the surface area of the anchor tag to span the full width of the menu
        width: 100%;
        padding: 0 0;
        font-weight: 600;
        line-height: 40px;
      }
      
        /* previous styles */
        dipslay: none;
      }

      .navigation-menu.expanded .listUl {
        display: block;
      }

      .getstartbtn {
        font-weight: 600;
        padding: 2px 15px !important;
        letter-spacing: 1px;
        display: inline-block;
        border-radius: 50px;
        transition: 0.5s;
        border: 2px solid ${styleConstants.PRIMARY_COLOR};
      }
    
       .getstartbtn:hover {
        background: ${styleConstants.PRIMARY_COLOR};;
        color: ${styleConstants.WHITE_COLOR};
        border: 2px solid ${styleConstants.SECONDARY_COLOR};
      }
  }
 
  @media (min-width: 320px) and (max-width: 767px) {
    .responsive-view{
        .navigation-menu .listU{
            height: calc(70vh - 77px);
        }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .responsive-view{
        .navigation-menu .listU{
            height: calc(50vh - 77px);
        }
          }
         .navigation-menu .listUl {
            height: fit-content;
         }
  }
  
`;
