import BannerImage from "../../../../assets/img/banner-images/background_3.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./pricing-page-styles";

export const PricingPage = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
            <div className="text-center">
              <div className="banner-section-title">
                <h2>Pricing </h2>
                <p></p>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        {/* ======= Pricing Section ======= */}
        <section id="Pricing" className="Pricing">
          <div className="container" data-aos="fade-up">
            <div className="pricing-section">
              {/* Tab Basic */}
              <div className="tabs-pricing">
                <div className="pricing__header basic">
                  <h4>Basic</h4>
                  <div className="pricing__short">
                    Just the features needed for multi-team resource management
                  </div>
                  <hr className="mp-separator" />
                  <div className="pricing__price">
                    <span>Starts At</span>
                    <h5>Rs ----</h5>
                    <span>per Year</span>
                  </div>
                  <div className="pricing__select">
                    <div
                      className="input input--radio"
                      data-tid="mpp-pricing-pricing-basic"
                      id="basic"
                    ></div>
                  </div>
                </div>
                <div className="pricing__desc">
                  <div>
                    <ul>
                      <li>50 resources included</li>
                      <li>Unlimited users</li>
                      <li>Project Portfolio Management</li>
                      <li>Resource Planning</li>
                      <li>Quick Import through copy &amp; paste</li>
                      <li>Team Planner</li>
                      <li>Self-Service Help Center</li>
                    </ul>
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                    <button className="buy-button">Buy</button>
                  </div>
                </div>
              </div>

              {/* //Tab Pro */}
              <div>
                <div className="pricing__header pricing__header--high high pro">
                  <h6 className="pricing__highlight">Most Popular</h6>
                  <h4>Pro</h4>
                  <div className="pricing__short">
                    Everything needed for strategy-driven portfolio management
                    and capacity planning
                  </div>
                  <hr className="mp-separator"></hr>
                  <div className="pricing__price pricing__price--package">
                    <span>Starts At</span>
                    <div className="pricing">
                      <h5>Rs -----</h5>
                      <span>per Year</span>
                    </div>
                    <div className="package">
                      <h5 className="package__price">Rs ----</h5>
                      <span className="package__payment">
                        One-time Onboarding Fee
                      </span>
                    </div>
                  </div>
                  <div className="pro_pricing__select">
                    <div
                      className="input pro-input--radio checked"
                      data-tid="mpp-pricing-pricing-pro"
                      id="pro"
                    ></div>
                  </div>
                </div>
                <div className="pricing__desc pricing__desc--high high">
                  <div>
                    <ul>
                      <li>50 resources included</li>
                      <li>Unlimited users</li>
                    </ul>
                    <strong>Everything from "Basic" plus:</strong>
                    <ul>
                      <li>Project prioritization</li>
                      <li>What-if scenarios</li>
                      <li>Multiple ways to view and analyze data</li>
                      <li>Integrations (e.g., Jira and Smartsheet)</li>
                      <li>Personal Customer Success Manager</li>
                    </ul>
                  </div>
                  <div className=" d-flex justify-content-center align-items-center">
                    <button className="buy-button">Buy</button>
                  </div>
                </div>
              </div>

              {/* Tab Premium */}
              <div className="tabs-pricing">
                <div className="pricing__header pricing_premium premium">
                  <h4>Premium</h4>
                  <div className="pricing__short">
                    All the features from Pro plus enterprise-level customer
                    success features and services
                  </div>
                  <hr className="mp-separator" />
                  <div className="pricing__price_popular">
                    <span>Starts At</span>
                    <h5>Rs. ----</h5>
                    <span>per Year</span>
                  </div>
                  <div className="pricing__select">
                    <div
                      className="input input--radio"
                      data-tid="mpp-pricing-pricing-premium"
                      id="premium"
                    ></div>
                  </div>
                </div>

                <div className="pricing__desc pricing__desc--premium premium">
                  <div>
                    <ul>
                      <li>50 resources included</li>
                      <li>Unlimited users</li>
                    </ul>
                    <strong>Everything from "Pro" plus:</strong>
                    <ul>
                      <li>Project prioritization</li>
                      <li>What-if scenarios</li>
                      <li>Multiple ways to view and analyze data</li>
                      <li>Integrations (e.g., Jira and Smartsheet)</li>
                      <li>Personal Customer Success Manager</li>
                    </ul>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <button className="buy-button">Buy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Pricing Section */}
      </Wrapper>
    </StaticSkeleton>
  );
};
