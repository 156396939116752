import { Grid } from "@mui/material";
import { debounce } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { MuiCustomTextField } from "../../../components/mui-customs/custom-textField";
import {
  errorNotify,
  successNotify,
} from "../../../components/notify-components/notify/notify.ui";
import { validateOrgDomain } from "../../../rest/subscriber.rest";

const ThirdStepDomainSelection = ({ editable }: { editable: boolean }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const validateSubDomain = debounce(async (e: any) => {
    const payload = {
      subdomain: e.target.value,
    };
    try {
      await validateOrgDomain(payload);
      successNotify("Domain is valid");
    } catch {
      errorNotify("Domain is not valid");
    }
  }, 1000);

  const handleInputChange = (e: any) => {
    setValue("subdomain", e.target.value);
    if (e.target.value.length >= 3) {
      console.log("value:", e.target.value.length, e.target.value);
      validateSubDomain(e);
    }
  };

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Controller
            name="subdomain"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MuiCustomTextField
                label="Organization Domain"
                placeholder=""
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={handleInputChange}
                error={Boolean(error)}
                helperText={error?.message?.toString()}
                disabled={editable}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThirdStepDomainSelection;
