import { APP_ROUTE } from "./routes.constants";
export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  SUBSCRIBER_ADMIN: "SUBSCRIBER_ADMIN",
  HR_MANAGER: "HR_MANAGER",
  EMPLOYEE: "EMPLOYEE",
  HR: "HR",
};

export const HEADINGS = [
  {
    link: APP_ROUTE.DASHBOARD,
    heading: "Dashboard",
  },
  {
    link: APP_ROUTE.TIME_SHEET,
    heading: "My TimeSheet",
  },
  {
    link: "/addtimesheets/all",
    heading: "Select Project",
  },
  {
    link: "/addtimesheets/",
    heading: "Add Hours",
  },
  {
    link: APP_ROUTE.TASK,
    heading: "Tasks",
  },
  {
    link: APP_ROUTE.PROJECT,
    heading: "Projects",
  },
  {
    link: APP_ROUTE.REPORTS,
    heading: "Reports",
  },
  {
    link: APP_ROUTE.SETTINGS,
    heading: "Settings",
  },
  {
    link: APP_ROUTE.IMPORTEXPORT,
    heading: "IP>EP",
  },
  {
    link: APP_ROUTE.MY_PROFILE,
    heading: "My Profile",
  },
  {
    link: APP_ROUTE.HR,
    heading: "Employee Timesheets",
  },
  {
    link: APP_ROUTE.MANAGE_USERS,
    heading: "Manage Users",
  },
  {
    link: APP_ROUTE.ON_BOARD_EMPLOYEE,
    heading: "Onboard Employee",
  },
];
