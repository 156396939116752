import { createGlobalStyle } from "styled-components";
// import { styleConstants } from './StyleConstants';

// -------------------BOOTSTRAP CDN -------------
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

// -------------------Font Awesome -------------
import "../../node_modules/font-awesome/css/font-awesome.css";

import "../assets/fonts/stylesheet.css";
import { styleConstants } from "./StyleConstants";

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`

@media (min-width: 1400px) {
  .container {
    max-width: 1420px;
  }
  .container-fluid {
    max-width: 1420px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: ${styleConstants.DARK_GREY_COLOR};
}
p{
  color: ${styleConstants.LIGHT_GREY_COLOR}; 
}
a{
    color: ${styleConstants.PRIMARY_COLOR};
}

ul li {
}
.font-weight-bold {
    font-weight: 600;
}
.color-primary{
    color: ${styleConstants.PRIMARY_COLOR};
}
.color-secondary{
    color: ${styleConstants.SECONDARY_COLOR};
}
.MuiTypography-root{
    font-family:${styleConstants.DEFAULT_FONT} !important;
}
.css-15yafy-MuiChip-root {
  font-family:${styleConstants.DEFAULT_FONT} !important;

}

a:hover {
  text-decoration: none;
}
.css-ahj2mt-MuiTypography-root { 
  font-family: ${styleConstants.DEFAULT_FONT};
}
.border {
  margin-bottom: 30px;
}
.form-group {
  label {
    font-weight: 600;
    color: ${styleConstants.DARK_GREY_COLOR};
  }
}
body {
    margin: 0;
    font-family: ${styleConstants.DEFAULT_FONT} !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .MuiCalendarPicker-root{
    background: ${styleConstants.WHITE_COLOR};
    border-radius: 15px;
  }

  .MuiInputBase-input{
    background: ${styleConstants.WHITE_COLOR};
    border: 3px solid ${styleConstants.BORDER_COLOR};
  }

  /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 10px 0 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f4f9;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: ${styleConstants.PRIMARY_COLOR};
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: ${styleConstants.PRIMARY_COLOR};
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}
.white-box {
  background: ${styleConstants.INNER_SHADOW_GRADIENT};
  background-blend-mode:${styleConstants.BACK_BLEND_MODE};
  border:${styleConstants.BORDER};
  box-shadow:${styleConstants.BOX_SHADOW_INSET3};
  border-radius:${styleConstants.BORDER_RADIUS_10};
  padding: 20px;
  border-top: 0.3rem solid ${styleConstants.PRIMARY_COLOR};
}

.banner-section-title{
    h2{
        color: ${styleConstants.WHITE_COLOR};
        font-size: 75px;
        font-weight: 900;
        text-transform: capitalize;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: ${styleConstants.SECONDARY_COLOR};
            bottom: -7px;
            left: calc(50% - 10px);
          }
  
          &::before {
            content: "";
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: #ddd;
            bottom: 1px;
            left: calc(50% - 60px);
          }
    }
  }

  .submit-btn {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    color: ${styleConstants.WHITE_COLOR};
    background: ${styleConstants.PRIMARY_COLOR};
    border: 2px solid ${styleConstants.WHITE_COLOR};
  }

   .submit-btn:hover {
    background: #fff;
    color: ${styleConstants.SECONDARY_COLOR};
    border: 2px solid ${styleConstants.PRIMARY_COLOR};
  }

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: ${styleConstants.PRIMARY_COLOR} !important;
}
.main-heading {
    color: ${styleConstants.PRIMARY_COLOR};
    font-weight: 600;
   p{
    font-size: 1.5rem;
   }
}
// buttons//

.orange-btn{
background: ${styleConstants.PRIMARY_COLOR};
background-blend-mode:${styleConstants.BACK_BLEND_MODE};
border:${styleConstants.BORDER};
box-shadow:${styleConstants.BOX_SHADOW_INSET3};
border-radius:${styleConstants.BORDER_RADIUS_10};

}
.p-datepicker-trigger{
    background: ${styleConstants.PRIMARY_COLOR};
    background-blend-mode:${styleConstants.BACK_BLEND_MODE};
    border:${styleConstants.BORDER};
    box-shadow:${styleConstants.BOX_SHADOW_INSET3};
    border-radius:${styleConstants.BORDER_RADIUS_10};
    &:hover{
        background: ${styleConstants.SECONDARY_COLOR};
     }
}
.p-button:enabled:hover{
    background: ${styleConstants.SECONDARY_COLOR}; 
}

//end buttons//
.main-container{
  margin-left:15%;
  margin-bottom: 20px;
  padding:20px;
}
.app-navbar {
  margin-left:15%;
  margin-bottom: 20px;
  padding:20px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .MuiInputLabel-formControl{
    font-size: 14px !important;
  }
  .app-navbar {
    margin-left:0%;
  }
  .responsive-icon {
    margin-left: 2rem;

  }
  .left-curve-img{
   display:none;
  }
  .right-curve-img{
    display:none;
  }
  .maim-content-head-top{
    margin: 2rem 1rem;
    padding: 10px 0 0;
  }
  
  .time-section{
    .card-box {
        .section-content{
            margin-bottom:
            p{
                margin-left: 0rem;
            }
        }
    }
  } 
    .banner-section-title{
        h2{
            font-size: 42px;
        }
      }
}


///
@media (min-width: 768px) and (max-width: 1024px){
    .left-curve-img{
        display:none;
       }
       .right-curve-img{
         display:none;
       }
       .maim-content-head-top{
         margin: 2rem 1rem;
         padding: 10px 0 0;
       }
       
       .time-section{
         .card-box {
             .section-content{
                 margin-bottom:
                 p{
                     margin-left: 0rem;
                 }
             }
         }
       } 
         .banner-section-title{
             h2{
                 font-size: 42px;
             }
           }
}
//management section css start//

.time-section {
    margin-top: 5rem;
    margin-bottom: 5rem;

    .card-box {
      position: relative;
      margin-top: 15rem;

      .img-center {
        .img-circle-top {
          height: 240px;
          width: 240px;
          overflow: hidden;
          position: absolute;
          left: 40.7%;
          top: -133px;
          z-index: 2;
          background: ${styleConstants.PRIMARY_COLOR};
          border-style: solid;
          border-color: ${styleConstants.WHITE_COLOR};
          border-width: 15px;
          border-radius: 50%;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          transition: 0.4s ease;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          &:hover {
            background-color: ${styleConstants.SECONDARY_COLOR};
          }
        }
      }

      .img-circle-top:hover img {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
      }

      .img-circle-left:hover img {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
      }

      .maim-content-head-top {
        border-left: 16px solid ${styleConstants.PRIMARY_COLOR};
        border-radius: 60px 60px 60px 60px;
        background-color: ${styleConstants.WHITE_COLOR};
        border-right: 16px solid ${styleConstants.PRIMARY_COLOR};
        // margin: 12px 0px;
        z-index: -1;
        border-radius: 40px 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-size: 26px;
          color: ${styleConstants.PRIMARY_COLOR};
          font-weight: 900;
          letter-spacing: 2px;
        }
      }
      .maim-content-head-right {
        border-left: 16px solid ${styleConstants.PRIMARY_COLOR};
        background-color: ${styleConstants.WHITE_COLOR};
        border-right: 16px solid ${styleConstants.PRIMARY_COLOR};
        // margin: 12px 0px;
        z-index: -1;
        border-radius: 0 40px 40px 10px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          left: 24%;
          top: 55px;
          font-size: 26px;
          color: ${styleConstants.PRIMARY_COLOR};
          font-weight: 900;
          letter-spacing: 2px;
        }
      }

      .section-content {
        border-left: 16px solid ${styleConstants.PRIMARY_COLOR};
        border-radius: 60px 60px 60px 60px;
        background-color: ${styleConstants.WHITE_COLOR};
        border-right: 16px solid ${styleConstants.PRIMARY_COLOR};
        z-index: -1;
        border-radius: 40px 40px 10px 40px;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;

        p {
          // width: 30rem;
          margin-left: 5rem;
          display: flex;
          align-items: center;
          padding: 24px;
          letter-spacing: 0;
          color:${styleConstants.PRIMARY_COLOR};
          margin-bottom: 0;
          font-weight: 600;
        }
      }
      .section-content-left {
        border-left: 16px solid ${styleConstants.PRIMARY_COLOR};
        border-radius: 60px 60px 60px 60px;
        background-color: ${styleConstants.WHITE_COLOR};
        border-right: 16px solid ${styleConstants.PRIMARY_COLOR};
        z-index: -1;
        border-radius: 40px 40px 10px 40px;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;

        p {
          width: 32rem;
          margin-left: 1rem;
          display: flex;
          align-items: center;
          padding: 24px;
          letter-spacing: 0;
          color:${styleConstants.PRIMARY_COLOR};
          margin-bottom: 0;
          font-weight: 600;
        }
      }
    }
  }

///end///

////timesheet css//
.approve-btn {
  svg {
    font-size: 20px;
    margin-left: 10px;
  }
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  margin-bottom: 20px;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  background-color: lightblue;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root { 
  background-color: lightblue !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.addmore_btn {
  margin-top: 15px;
  .css-i4bv87-MuiSvgIcon-root {
    fill: ${styleConstants.WHITE_COLOR};
  }
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.addmore_btn_task {
  margin: 15px  15px;
  .css-i4bv87-MuiSvgIcon-root {
    fill: ${styleConstants.WHITE_COLOR};
  }
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: ${styleConstants.PRIMARY_COLOR} !important;
}
.css-1dm1o0z-MuiButtonBase-root-MuiFab-root {
  background-color: ${styleConstants.PRIMARY_COLOR} !important;

}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  // background-color: #000 !important;
}
 .p-float-label .p-component {
  border: 3px solid ${styleConstants.BORDER_COLOR};
  border-radius:${styleConstants.BORDER_RADIUS_10};
}
.p-multiselect{
    border: 3px solid ${styleConstants.BORDER_COLOR};
    border-radius:${styleConstants.BORDER_RADIUS_10};
}
.p-tabview-panels {
    border-radius: 15px;
    box-shadow: 0 0 5pt 0.5pt #D3D3D3;
}
..p-fileupload{
    border-radius: 15px;
    box-shadow: 0 0 5pt 0.5pt #D3D3D3;
}
.p-calendar-w-btn-right .p-inputtext {
    border:${styleConstants.BORDER_COLOR};
    // box-shadow:${styleConstants.INNER_BOX_SHADOW};
    border-radius:${styleConstants.BORDER_RADIUS_10};
}
.p-datepicker-trigger {
    box-shadow: none;
}

.p-float-label h6 {
    font-weight: 600;
}
section {
    padding: 10px;
}

.p-button {
    background:${styleConstants.PRIMARY_COLOR};
    border:${styleConstants.BORDER_COLOR};
}

// ./////news admin design

main{
    background: ${styleConstants.TERTIARY_COLOR};
    height: 100vh;
}

.css-m9glnp-MuiPaper-root-MuiDialog-paper{
  background: ${styleConstants.TERTIARY_COLOR} !important;
}
.css-7ytl41-MuiListItem-root {
  background: ${styleConstants.TERTIARY_COLOR} !important;
  border-radius: 10px;
  margin-bottom: 5px;
  border-right: 3px solid ${styleConstants.PRIMARY_COLOR};
  padding: 2px 10px !important;
}

.MuiButton-root {
    font-weight: 600 !important;
    border-width: 2.5px!important;
    border-radius: 15px !important;
}

.css-xelq0e-MuiPickerStaticWrapper-content{
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.box-container {
    border-radius: 15px;
    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background: #fff;
    padding: 1rem;
}

.datepicker-m-y input{
    font-weight: bold;
    font-size: 21px;
}

.datepicker-m-y label{
    font-weight: bold;
    font-size: 15px;
}

.css-3tt260-MuiInputBase-root-MuiInput-root:before {
    border-bottom: none !important;
}
.css-3tt260-MuiInputBase-root-MuiInput-root:before{
    border-bottom: none !important;
}
.g-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.g-shadow2{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
    backdrop-filter: blur(3px);
    background-color: rgba(244,245,247,0.6) !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    backdrop-filter: blur(3px);
    background-color: rgba(244,245,247,0.6) !important;
}
.profile-hover{
    padding: 5px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

.MuiDialog-paper{
    border-radius: 15px !important;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}

.drawer-container{
    width: 700px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .drawer-container{
        width: 450px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
    .drawer-container{
        width: 500px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .drawer-container{
        width: 700px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .drawer-container{
        width: 700px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .drawer-container{
        width: 700px;
    }
}

`;
