import BannerImage from "../../../../assets/img/banner-images/background_4.png";
import StaticSkeleton from "../../../components/static/static-skeleton-component/static-skeleton.component";
import { Wrapper } from "./about-us-style";

export const AboutUsPage = () => {
  return (
    <StaticSkeleton>
      <Wrapper image={BannerImage}>
        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div className="container">
          <div className="text-center">
            <div className="banner-section-title">
                <h2>ABOUT US</h2>
                </div>
          </div>
          </div>
        </section>
        <div className="container">
          <div className="row about-us-contain">
            <div className="col-lg-6">
              <p>
                  DockTimer is a SaaS-based web application for Time tracking
                  that offers organizations employee time tracking facility and
                  HR solutions to help them manage and grow their businesses
                  more efficiently.
                </p>
                <p>
                  Our purpose is to make the lives of entrepreneurs and managers
                  easier by providing an automatic time tracking tool that will
                  help organizations save time and focus on their primary
                  business goals.
                </p>
            </div>
            <div className="col-lg-6">
              
            </div>
          </div>
            
          </div>
        {/* End Hero */}
      </Wrapper>
    </StaticSkeleton>
  );
};
