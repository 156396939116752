import { InitOptions } from "i18next";
import { map } from "lodash";
import enUS from "../locale/languages/en-us.json";

export enum SupportedLocales {
  English = "en-US",
  Français = "fr-FR",
}

export const languageSelectOptions = map(SupportedLocales, (value, key) => ({
  label: key,
  value,
}));

// Returns i18next options
export const getInitOptions = (): InitOptions => {
  return {
    supportedLngs: Object.values(SupportedLocales),
    resources: {
      [SupportedLocales.English]: { translation: enUS },
    },
    fallbackLng: [SupportedLocales.English],
    detection: {
      order: ["cookie"],
      caches: ["cookie"], // cache user language on
    },
    interpolation: {
      escapeValue: false, // XSS safety provided by React
    },
    missingKeyHandler: (_lngs, _ns, key) =>
      // eslint-disable-next-line no-console
      console.error(`i18next: key not found "${key}"`),
    saveMissing: true, // Required for missing key handler
  };
};
