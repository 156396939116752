import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import AppStoreProvider from "../persist-store/app-store";
import i18n from "../services/local-utils";
import { GlobalStyle } from "../styles/global-styles";
import AppRouter from "./components/app-router/app-router";
import AppWrapper from "./components/app-wrapper/app-wrapper";

const theme = createTheme({
  typography: {
    // fontFamily: `"Montserrat", sans-serif`,
    // fontSize: 14
  },
  palette: {
    primary: {
      main: "#00031f",
    },
    secondary: {
      main: "#ff9f42",
    },
  },
});

const App = () => {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Toaster position="top-right" reverseOrder={false} />
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AppStoreProvider>
              <AppWrapper>
                <AppRouter />
              </AppWrapper>
              <GlobalStyle />
            </AppStoreProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;
